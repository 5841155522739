import { render, staticRenderFns } from "./StudioSelect.vue?vue&type=template&id=59561e94&scoped=true&"
import script from "./StudioSelect.vue?vue&type=script&lang=js&"
export * from "./StudioSelect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59561e94",
  null
  
)

export default component.exports