import { isEmpty, isString, has, merge, isObject } from 'lodash'
import get from 'lodash.get'
import { API } from '@/services/brand'
import { API as API_V2 } from '@/services/v2/brand'

const { definitionRawData: definitionRawDataV1, pathRawData: pathRawDataV1 } = require("../services/raw-data")
const operationIdsV1 = Object.keys(pathRawDataV1)

// v2
const { definitionRawData: definitionRawDataV2, pathRawData: pathRawDataV2 } = require("../services/v2/raw-data")
const operationIdsV2 = Object.keys(pathRawDataV2)

const getType = (item) => {
  if (item.ref) {
    return item.ref
  } else if (item.type) {
    return item.type
  } else if (item.anyOf) {
    const val = item.anyOf.find(v => v?.$ref)

    if (val) {
      return val.$ref.replace('#/definitions/', '')
    } else {
      return 'string'
    }
  } else {
    return 'string'
  }
}

const getColumns = (properties = {}, config = {}) => {
  const { order = [], customColumns } = config
  const keys = Object.keys(properties)
  let result = []

  console.log('customColumns', customColumns)
  order.forEach(column => {
    if (isString(column)) {
      const prop = column
      if (keys.includes(prop)) {
        const v = properties[prop]
        let item = {
          prop: prop,
          title: v.description,
          type: getType(v)
        }

        if (v['x-i18n']) {
          item['x-i18n'] = v['x-i18n']
        }

        if (customColumns && customColumns[prop]) {
          item = merge(item, customColumns[prop])
        }
        result.push(item)
      } else {
        console.error(`Unknown column: ${prop}`)
      }
    } else if (isObject(column)) {
      if (column.renderIf !== false) {
        const prop = column.prop
        if (prop && keys.includes(prop)) { // customed column 
          const v = properties[prop]
          let item = {
            prop: prop,
            title: v.description,
            type: getType(v)
          }
  
          if (v['x-i18n']) {
            item['x-i18n'] = v['x-i18n']
          }
  
          if (customColumns && customColumns[prop]) {
            item = merge(item, customColumns[prop])
          }
  
          item = merge(item, column)
  
          result.push(item)
        } else if (column.render && column.title) { // virtual column
          result.push(column)
        } else {
          console.error(`virtual column must assign title and render func: ${JSON.stringify(column)}`)
        }
      }
    }
  })

  return result
}

const getSchema = (properties, config = {}) => {
  const { order = [], customSchema } = config
  const keys = Object.keys(properties)
  let result = []

  order.forEach(key => {
    const hasKey = keys.includes(key)

    if (hasKey) {
      const v = properties[key]
      let item = {
        prop: key,
        title: v.description,
        type: getType(v),
        required: v.required,
        desc: v.description
      }

      if (v['x-i18n']) {
        item['x-i18n'] = v['x-i18n']
      }
      if (v.items) {
        item.items = v.items
      }
      if (customSchema && customSchema[key]) {
        item = merge(item, customSchema[key])
      }

      result.push(item)
    }
  })

  return result
}

export const getAPI = (apiVersion = "v1") => {
  return apiVersion === 'v2' ? API_V2 : API
}

export const isCreateOperationId = (id) => id.endsWith('Create')

export const isUpdateOperationId = (id) => id.endsWith('Update')

export const getShowByOperationId = (id) => {
  return id.replace(/(.*)[A-Z].*/g, "$1Show")
}

export const getIndexByOperationId = (id) => {
  return id.replace(/(.*)[A-Z].*/g, "$1Index")
}

export const getApiData = (apiVersion = "v1") => {
  let _operationIds, _definitionRawData, _pathRawData
  if (apiVersion === 'v2') {
    _operationIds = operationIdsV2
    _definitionRawData = definitionRawDataV2
    _pathRawData = pathRawDataV2
  } else {
    _operationIds = operationIdsV1
    _definitionRawData = definitionRawDataV1
    _pathRawData = pathRawDataV1
  }

  return {
    operationIds: _operationIds,
    definitionRawData: _definitionRawData,
    pathRawData: _pathRawData
  }
}

export const getTableColumns = (operationId, config) => {
  const { apiVersion } = config
  const { operationIds, definitionRawData, pathRawData } = getApiData(apiVersion)

  if (!operationIds.includes(operationId)) {
    throw Error('operationId 不存在')
  }

  const responseType = get(pathRawData[operationId], 'responses.$ref')
  const properties = definitionRawData[responseType]?.properties
  let result

  if (properties?.pagination) {
    const props = properties.data.items.properties
    result = getColumns(props, config)
  } else {
    const props = properties
    result = getColumns(props, config)
  }

  return result
}

export const getFormSchema = (operationId, config) => {
  const { apiVersion } = config
  const { operationIds, definitionRawData, pathRawData } = getApiData(apiVersion)

  if (!operationIds.includes(operationId)) {
    throw Error('operationId 不存在')
  }

  const responseType = get(pathRawData[operationId], 'parameters.body.json.schema.$ref')
  let properties
  let result

  if (responseType) {
    properties = definitionRawData[responseType]?.properties
  } else {
    properties = get(pathRawData[operationId], 'parameters.body.json.properties.properties')
  }

  if (!properties) {
    throw Error(`${operationId} 没有找到对应的类型。`)
  }

  if (responseType === 'AttachmentForm') {
    result = [{
      prop: 'signedId',
      title: '图片',
      type: 'AttachmentForm',
      desc: '图片'
    }]
  } else {
    if (properties?.pagination) {
      const props = properties.data.items.properties
      result = getSchema(props, config)
    } else {
      const props = properties
      result = getSchema(props, config)
    }
  }

  return result
}

export const getDialogTitle = (operationId, apiVersion="v1") => {
  const { pathRawData } = getApiData(apiVersion)

  const item = pathRawData[operationId]

  if (!item) {
    throw Error(`没有找到 ${operationId} 对应的请求。`)
  }

  return get(item, 'summary')
}

export const getTableFilterSchema = (operationId, config = {}) => {
  const { apiVersion } = config
  const { operationIds, definitionRawData, pathRawData } = getApiData(apiVersion)

  if (!operationIds.includes(operationId)) {
    throw Error('operationId 不存在')
  }

  let result = []
  const parameters = get(pathRawData[operationId], 'parameters.query')
  const ignored = ['page', 'pageSize']

  if (isEmpty(parameters)) {
    return result
  } else {
    const { order } = config

    Object.entries(parameters).forEach(([k, v]) => {
      if (ignored.includes(k)) {
        return
      }

      let item = {
        prop: k,
        type: getType(v),
        desc: v.description
      }

      if (v['x-i18n']) {
        item['x-i18n'] = v['x-i18n']
      }
      if (v.items) {
        item.items = v.items
      }

      if (!order) {
        result.push(item)
      } else {
        if (order.includes(item.prop)) {
          result.push(item)
        }
      }
    })

    return result
  }
}
