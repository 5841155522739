export default {
  props: {
    resource: {},
    submitForm: {
      type: Function,
      required: true,
    }
  },

  data: function() {
    return {
      isLoading: false,
    }
  },

  methods: {
    fetchOptions(){
      
    }
  },

  watch :{
    resource(){
      this.fetchOptions()
    }
  },

  created() {
    this.fetchOptions()
  }

}
