<template>
  <el-select 
    v-model="membershipId" 
    size='small' 
    class='item'
    :placeholder="placeholder" 
    :filterable='category'
    :filter-method="filterMethod"
    v-bind="$attrs"
  >
    <template v-if='this.category'>
      <el-option-group
        v-for="group in (filteredOptions || sourceData)"
        :key="group.name"
        :label="group.name"
      >
        <el-option
          v-for="item in group.options"
          :key="item.name + item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-option-group>
    </template>
    <template v-else>
      <el-option
        v-for="item in sourceData"
        :key="item.name + item.id"
        :label="item.name"
        :value="item.id">
      </el-option>
    </template>
  </el-select>
</template>

<script>
import { fetchMembershipCards, fetchMembershipCardOptions } from '@/api/global'
import fetch from '@/mixins/fetch'
import { cloneDeep, groupBy } from "lodash"
import { filterFalsy } from '@/utils/'
import { SALE_TYPES } from "@/constant/entries";

export default {
  name: 'MfMembership',
  
  mixins: [fetch],
  
  props: {
    value: null,
    all: {
      type: Boolean,
      default: true,
    },
    params: Object,
    category: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '会员卡',
    },
    options: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: true,
    },
    groupBy: {
      type: String,
      default: 'cardName',
    },
    defaultValueIndex: {
      type: Number,
    },
    productType: {
      type: String,
      default: 'group_training'
    },
    memberId: null,
  },
  
  data() {
    const { active } = this
    
    return {
      membershipId: this.value,
      fetchApi: (params) => {
        return this.options ? fetchMembershipCardOptions({...params, active}) : fetchMembershipCards({...params, active})
      },
      filteredOptions: null,
    }
  },

  model: {
    prop: 'value',
    event: 'change',
  },
  
  watch: {
    params: {
      handler: function() {
        this.fetchData()
      },
      deep: true,
    },

    memberId() {
      this.fetchData()
    },

    membershipId(val) {
      this.change(val)
    },
  },
  
  methods: {
    filterMethod(query) {
      const includes = (v = '') => v.includes(query)
      const mapDeep = arr => arr.some(item => includes(item.cardName) || includes(item.fullName) || includes(item.name))
      let result = []
      this.sourceData.forEach(item => {
        if (includes(item.name)) {
          result.push(item)
        } else if (mapDeep(item.options)) {
          result.push(item)
        }
      })

      this.filteredOptions = result
    },
    
    makeParams() {
      let params = {...this.params}
      let memberId = this.memberId
      let actualParams = {}
      if (memberId && this.options) {
        actualParams = {
          memberId,
          ...params,
        }
      }
      return actualParams
    },

    formatData(data) {
      if (this.active) {
        data = data.filter(item => item.active)
      }
      data.forEach(item => {
        let saleType = item.saleType !== 'normal' ? ` / ${SALE_TYPES[item.saleType] ? SALE_TYPES[item.saleType].label: ''}` : ''
        item.cardName = item.cardName + saleType
      })
      let obj = data
      let result = []
      if (this.category) {
        obj = groupBy(obj, this.groupBy)
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            const value = obj[key]
            let name = key
            if (key === 'group_training') {
              name = '团课'
            } else if (key === 'elite_training') {
              name = '私教'
            }
            result.push({
              name,
              options: value
            })
          }
        }
        obj = result
      }
      if (this.all && !this.category) {
        return [{ name: '全部', id: null }, ...obj]
      }
      if (this.all) {
        return [
          { name: '全部', options: [{ name: '全部', id: null }] },
          ...obj,
        ]
      }
      
      // 默认选中第一张卡
      let index = this.defaultValueIndex
      if (index && !this.value) {
        if (this.category === true) {
          this.membershipId = obj[0].options[0].id
        } else {
          this.membershipId = obj[0].id
        }
      }

      this.filteredOptions = obj
      return obj
    },
    
    change(value) {
      this.$emit('change', value)
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
