<template>
  <div>
    <div v-if="url" class='box' :style='style' @click='$emit("click")'></div>
  </div>
</template>

<script>
export default {
  name: 'MfImageBox',

  props: {
    size: {
      type: Number,
      default: 100,
    },
    height: null,
    width: null,
    url: null,
  },

  data() {
    return {
    }
  },
  
  computed: {
    actualWidth() {
      if (this.size) {
        return this.size
      }
      return this.width
    },

    actualHeight() {
      if (this.size) {
        return this.size
      }
      return this.height
    },

    style() {
      return {
        backgroundImage: `url(${this.url})`,
        width: this.actualWidth + 'px',
        height: this.actualHeight + 'px',
      }
    }
  },
  
  methods: {
    
  },
}
</script>

<style lang='scss' scoped>
.box {
  background: no-repeat 0 0 / cover;
  cursor: zoom-in;
}
</style>