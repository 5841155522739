import request from 'utils/request'

export function fetchWorkouts(params) {
  return request({
    url: '/workouts',
    method: 'get',
    params
  })
}

export function fetchWorkout(workoutId) {
  return request({
    url: '/workouts/' + workoutId,
    method: 'get'
  })
}

export function createWorkout(data) {
  return request({
    url: '/workouts',
    method: 'post',
    data,
  })
}

export function updateWorkout(workoutId, data) {
  return request({
    url: `/workouts/${workoutId}`,
    method: 'put',
    data,
  })
}

export function fetchWorkoutVersions(workoutId, params) {
  return request({
    url: '/workouts/' + workoutId + '/workout_versions',
    method: 'get',
    params
  })
}

export function fetchPackages(workoutId, params) {
  return request({
    url: '/workouts/' + workoutId + '/packages',
    method: 'get',
    params
  })
}

export function moveUp(workoutId) {
  return request({
    url: `/workouts/${workoutId}/move_up`,
    method: 'put',
  })
}

export function updateWorkoutsPrice(data) {
  return request({
    url: `/workouts/update_price`,
    method: 'put',
  })
}

