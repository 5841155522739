<template>
  <div @click="handleClick" :class="{'status-btn': true, [type]: true, 'clickable': clickable}"></div>
</template>

<script>
export default {
  name: 'MfStatusDot',
  props: {
    type: String,
    clickable: {
      type: Boolean,
      default: false,
    },
  },
  
  data() {
    return {
      
    }
  },
  
  methods: {
    handleClick() {
      if (this.clickable) {
        this.$emit('click')
      }
    }
  },
}
</script>

<style lang='scss' scoped>
.status-btn {
  width: 11px;
  height: 11px;
  border-radius: 50%;

  &.clickable {
    position: relative;
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      background-color: transparent;
      left: -10px;
      top: -10px;
      width: calc(100% + 20px);
      height: calc(100% + 20px);
    }
  }

  &.green {
    background: #00ca56;
    border: 1px solid #14ae46;
  }

  &.red {
    background: #ff5c5c;
    border: 1px solid #e33e41;
  }

  &.orange {
    background: #ffcf40;
    border: 1px solid #ffcf40;
  }

  &.grey {
    background: #dddddd;
    border: 1px solid #cccccc;
  }
}
</style>
