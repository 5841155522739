<template>
  <el-select v-model="current" 
              placeholder="请选择代金券" 
              v-loading='isLoading' 
              :disabled="disabled"
              :filterable="true">
    <el-option v-for="item in sourceData"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    >
    </el-option>
  </el-select>
</template>

<script>
import select from '@/mixins/select'
// import { fetchCouponsList, fetchCoupon } from '@/api/coupon'
import { API } from '@/services/brand'
import { xorBy } from 'lodash'

export default {
  name: 'MfCoupons',
  
  mixins: [select],
  
  props: {
    id: null,
    excludes: Array,
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  
  data() {
    return {
      isLoading: true,
      sourceData: null,
    }
  },
  
  created() {
    this.fetchData()
  },
  
  methods: {
    fetchData() {
      this.isLoading = true
      API.couponCouponList({active: true}).then(res => {
        let data = res
        if (this.excludes && this.excludes.length) {
          data = xorBy(res, this.excludes.map(id => ({id})), 'id')
        }
        this.sourceData = data.map(o => ({label: `${o.name} (${o.validityPeriod}天)` , value: o.id}))
        this.isLoading = false
      })
    },
  },
}
</script>

<style lang='scss' scoped>

</style>