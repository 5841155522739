<template>
  <div class="mf-search item">
    <el-input 
      :placeholder="placeholder"
      v-model='search'
      size='small'
      @keyup.enter.native='handleEnterDown'
    />
    <el-button 
      type='primary'
      icon="el-icon-search"
      size='small'
      @click='handleClick'
    >搜索</el-button>
  </div>  
</template>

<script>
import { fetchStudios } from '@/api/coach'

export default {
  name: 'MfSearch',
  
  props: {
    placeholder: {
      type: String,
      default: '输入姓名、手机号搜索',
    },
    value: String,
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  data() {
    return {
      search: this.value,
    }
  },

  watch: {
    value() {
      this.search = this.value
    },
  },

  methods: {
    handleEnterDown() {
      this.handleClick()
    },

    handleClick() {
      let { search } = this
      this.$emit('change', search)
    },
  },
}
</script>

<style lang="scss" scoped>
.mf-search {
  display: flex;
}

.el-button {
  margin-left: 8px;
}
</style>
