<template>
  <el-menu class="navbar" mode="horizontal">
    <img :src="logoUrl" alt="logo" class="logo">
    <breadcrumb></breadcrumb>
    <div class="right-container">
      <el-dropdown trigger="click">
        <div class="avatar-wrapper">
          <strong class="brand-name">{{brandName}}</strong>
          <span class="name">{{name}}</span>
          <i class="el-icon-caret-bottom"></i>
        </div>
        <el-dropdown-menu class="user-dropdown" slot="dropdown">
          <div >
            <el-dropdown-item>
              品牌ID：{{brandIdentifier}}
            </el-dropdown-item>
            <el-dropdown-item v-if="brandExpiryDate">
              到期时间：{{brandExpiryDate}}
            </el-dropdown-item>
          </div>
          <router-link class="inlineBlock" to="/">
            <el-dropdown-item divided>
              <i class='icon icon-home'></i> 主页
            </el-dropdown-item>
          </router-link>
          <div @click="openDialog('editPassword')">
            <el-dropdown-item>
              <i class='icon icon-lock'></i> 修改密码
            </el-dropdown-item>
          </div>
          <div @click="openDialog('brandQrCodeDialog')" style="display:block;">
            <el-dropdown-item divided>
              <i class='icon icon-qrcode'></i> 小程序码
            </el-dropdown-item>
          </div>
          <div v-if="brand.length > 1" @click="openDialog('changeDialog')">
            <el-dropdown-item>
              <i class='icon icon-level'></i> 切换品牌
            </el-dropdown-item>
          </div>
          <div @click="logout">
            <el-dropdown-item divided>
              <i class='icon icon-logout'></i> 登出
            </el-dropdown-item>
          </div>
        </el-dropdown-menu>
      </el-dropdown>
    
      <NotificaitonInbox @close="closeDialog" />  
      <a :href="helpDocUrl" class="help" target="_blank"><i class="el-icon-question"></i> 帮助</a>
    </div>
    
    <EditPassword @close="closeDialog" />
    <change-brand @close="closeDialog" />
    
    <MfImageDialog name="brandQrCodeDialog" title='品牌二维码' :url='brandQrCode' />
  </el-menu>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb.vue'
import EditPassword from './EditPassword.vue'
import ChangeBrand from './ChangeBrand.vue'
import NotificaitonInbox from '@/views/notification/Inbox.vue'
import router from '@/router'

export default {
  components: {
    Breadcrumb,
    EditPassword,
    ChangeBrand,
    NotificaitonInbox
  },

  data() {
    return {
      helpDocUrl: null
    }
  },

  computed: {
    ...mapGetters(['avatar', 'name', 'brandQrCode', 'brandName', 'brandIdentifier', 'brandExpiryDate']),
    ...mapState({
      brand: (state) => {
        return state.user.brand
      },
    }),

    logoUrl() {
      return require('@/assets/images/logo_black.png')
    }
  },

  created() {
    this.$store.dispatch('fetchBrands')
    this.updateHelpDocUrl(router.currentRoute)
  },

  watch: {
    '$route'(to, from) {
      this.updateHelpDocUrl(router.currentRoute)
    }
  },

  methods: {
    openDialog(name) {
      this.$store.commit({
        type: 'OPEN_DIALOG',
        name,
      })
    },

    closeDialog() {
      this.$store.commit('CLOSE_DIALOG')
    },

    logout() {
      this.$store.dispatch('logOut').then(() => {
        location.reload()
      })
    },

    updateHelpDocUrl(route) {
      if (route.meta.helpDoc) {
        this.helpDocUrl = route.meta.helpDoc
        return
      }

      for (var matchedRoute of route.matched) {
        let doc = this.recurFindDoc(matchedRoute)
        if (doc) {
          this.helpDocUrl = doc
          return
        }
      }

      this.helpDocUrl = 'https://www.yuque.com/ap1q4q/ngd5zk/pc73bx'
    },

    recurFindDoc(route) {
      if (route.meta && route.meta.helpDoc) {
        return route.meta.helpDoc
      } else if (route.parent) {
        return this.recurFindDoc(route.parent)
      } else {
        return null
      }
    }
  },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.navbar {
  height: 50px;
  line-height: 50px;
  border-radius: 0px !important;
  display: flex;
  align-items: center;
  .hamburger-container {
    line-height: 58px;
    height: 50px;
    float: left;
    padding: 0 10px;
  }
  .screenfull {
    position: absolute;
    right: 90px;
    top: 16px;
    color: red;
  }
  .right-container {
    height: 50px;
    display: inline-block;
    position: absolute;
    right: 35px;
    .avatar-wrapper {
      cursor: pointer;
      margin-top: 5px;
      position: relative;
      .brand-name {
        margin-right: 1em;
      }
      .el-icon-caret-bottom {
        position: absolute;
        right: -20px;
        top: 20px;
        font-size: 12px;
      }
    }
    .help {
      font-size: 14px;
      margin-left: 1em;
    }
  }
}

.logo {
  width: 120px;
  height: auto;
  margin-left: 40px;
  margin-right: 35px;
}
</style>
