import { fetchMembers } from '@/api/member'
import { PAGINATION_INFO } from '@/constant/entries'
import { isNumber } from "lodash";

const member = {
  state: {
  },

  mutations: {
  },

  actions: {
  },
}

export default member