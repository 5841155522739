<template>
  <el-select 
    v-model="levelId" 
    placeholder="员工等级"
    @change='change'
    class="select-gender item"
    v-bind="$attrs"
    size='small'
  >
    <!-- <el-option label="全部" value=""></el-option> -->
    <el-option-group
      v-for="category in data"
      :key="category.name"
      :label="category.name"
    >
      <el-option
        v-for="level in category.levels"
        :key="level.id"
        :label="level.name"
        :value="level.id">
      </el-option>
    </el-option-group>
  </el-select>
</template>

<script>

import { API } from '@/services/brand';
import { uniqBy, clone } from 'lodash'
export default {
  name: 'MfCoachLevel',

  props: {
    value: null,
    all: {
      type: Boolean,
      default: false,
    },
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  data() {
    return {
      data: null,
      levelId: this.value
    }
  },

  created() {
    this.fetchData()
  },

  watch: {
    value() {
      this.levelId = this.value
    }
  },

  methods: {
    fetchData() {
      this.isLoading = true
      API.coachLevelList().then(res => {
        let categories = uniqBy(res.map(level => clone(level.category)), (category) => category.id)
        categories.forEach((category) => {
          category.levels = []
          res.forEach((level) => {
            if (category.id == level.category.id) {
              category.levels.push(level)
            }
          })
        })
        this.data = categories
        this.isLoading = false
      })
    },

    change(value) {
      this.$emit('change', value)
    },
  },

  inheritAttrs: false,
}
</script>

<style lang='scss' scoped>
</style>
