<template>
  <section class="">
    <div v-if="showTips" class="tips" @click="handleClick" title='我知道自己在做什么'>你正在测试环境中连接正式服务器</div>

    <transition name="fade" mode="out-in">
      <keep-alive :include="cachedViews">
        <!-- <div class="app-container"> -->
          <router-view></router-view>
        <!-- </div> -->
      </keep-alive>
    </transition>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'AppMain',

  computed: {
    ...mapGetters(['cachedViews']),
    // key() {
    //   return this.$route.name !== undefined ? this.$route.name + +new Date() : this.$route + +new Date()
    // }

    showTips() {
      let show = process.env.NODE_ENV === 'development' && process.env.BASE_API === 'https://api.zishipower.com/brand'
      let hideByUser = sessionStorage.getItem('hideByUser')
      return show && (hideByUser !== '1')
    },
  },

  methods: {
    handleClick(e) {
      e.target.classList.add('hide')
      sessionStorage.setItem('hideByUser', '1')
    },
  }
}
</script>


<style lang="scss" scoped>
.tips {
  position: fixed !important;
  @include center(x);
  top: 70px;
  font-size: 30px;
  z-index: 2001;
  color: $warning;
  text-shadow: 2px 2px rgba(0, 0, 0, .05);
  cursor: pointer;
}
</style>
