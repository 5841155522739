<template>
  <div :class="{'mf-workout-picker': true, 'all-selected': all}" v-loading='isLoading'>
    <mf-radio v-model='all' :data='productData' />
    <el-checkbox-group v-if="sourceData" v-model='current'>
      <el-checkbox
        v-for="(item, i) in sourceData"
        :key='"checkbox" + i'
        :label='item.id'
      > {{item.name}} </el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script>
import { fetchWorkouts } from '@/api/workout'
import fetch from '@/mixins/fetch'

export default {
  name: 'mfWorkoutPicker',
  
  mixins: [fetch],
  
  props: {
    value: null,
    allOption: {
      type: Boolean,
      default: false,
    },
  },
  
  model: {
    prop: 'value',
    event: 'change',
  },
  
  data() {
    return {
      current: this.value,
      fetchApi: fetchWorkouts,
      productData: [
        { label: '全选', value: true },
        { label: '指定课程产品', value: false },
      ],
      all: this.allOption,
    }  
  },
  
  computed: {
  },
  
  watch: {
    value(val) {
      if (this.current !== val) {
        this.current = val
      }
    },

    current(val) {
      if (this.sourceData) {
        this.$emit('change', val)
      }
    },

    allOption(val) {
      if (this.all !== val) {
        this.all = val
      }
    },

    all(val) {
      if (val) {
        this.current = this.sourceData.map(item => item.id)
      } else {
        this.current = []
      }
      this.$emit('all', val)
    },
  },
  
  methods: {
    fetchCb() {
      // if (this.all) {
      //   this.all = this.allOption
      // }
    }
  },
}
</script>

<style lang='scss'>
</style>