<template>
  <el-dialog :visible='dialogVisible' ref='dialog' @close='closeDialog' class="sp-form-dialog" v-bind="dialogProps"
            v-on="$listeners">
    <slot></slot>
    <slot name="append"></slot>
    
    <div class='dialog-footer' slot='footer' v-if="showFooter">
      <slot name="footer"></slot>
      <el-button @click="closeDialog">取消</el-button>
      <el-button type='primary' @click="$emit('confirm')" :loading='isLoading'>{{confirmButtonText}}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { omit } from 'lodash'
export default {
  name: 'SpDialog',
  props: {
    name: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    confirmButtonText: {
      type: String,
      default: '确定'
    },
    showFooter: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {}
  },

  computed: {
    dialogVisible() {
      let { name } = this
      return this.$store.getters.dialogVisible(name)
    },

    dialogProps() {
      const dp = this.$attrs?.dialogProps || {}
      const result = {
        ...omit(this.$attrs || {}, 'dialogProps'),
        ...dp,
      }

      return result
    },
  },

  methods: {
    closeDialog() {
      this.$store.commit('CLOSE_DIALOG', { name: this.name })
    },
  },
}
</script>

<style lang='scss' scoped>
</style>
