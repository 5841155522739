<template>
  <div class="mf-filter-table">
    <slot name='row1'></slot>
    <div class="section content" :style="{padding: gutter + 'px'}">
      <slot name='row2'></slot>
      <mf-table
        ref='mf-table'
        v-on="$listeners"
        v-bind="bindData"
        customPageSize
        @changePage="changePage"
        @pageSizeChange="handleSizeChange"
      >
        <template 
          v-for="(item, key) in contentSlots" 
          :slot='key' 
        >
          <slot :name='key'></slot>
        </template>
      </mf-table>
    </div>
  </div>
</template>

<script>
import MfTable from './MfTable'
import { list } from '@/mixins/'
import { PAGINATION_INFO } from '@/constant/entries'
import { filterFalsy } from '@/utils/'
import { cloneDeep, isEqual } from 'lodash'

export default {
  name: 'MfFilterTable',
  
  mixins: [list],

  props: {
    config: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      default: 'list',
    },
    fetchApi: {
      type: Function,
      required: true,
    },
    paramsFunc: {
      type: Function,
    },
    formatFunc: {
      type: Function,
    },
    params: Object,
    indicatorsData: Array,
    gutter: {
      type: Number,
      default: 24,
    },
  },

  components: {
    MfTable,
  },

  data() {
    return {
      filter: {
        page: 1,
        pageSize: PAGINATION_INFO.pageSize,
      },
      total: 0,
      tableAutoFetch: false,
    }
  },

  computed: {
    copyConfig() {
      this.config.forEach(item => {
        if (item.formatter) {
          let func = item.formatter
          item.formatter = (...params) => {
            let cellValue = params[2]
            if (cellValue === null || cellValue === undefined) {
              return '--'
            } else {
              return func(...params)
            }
          }
        }
      })
      return cloneDeep(this.config)
    },

    bindData() {
      let result = {
        data: this.sourceData,
        columnsConfig: this.copyConfig,
        total: this.total,
        page: this.actualParams.page,
        pageSize: this.actualParams.pageSize,
        isLoading: this.isLoading,
        type: this.type,
        indicatorsData: this.indicatorsData,
        ...this.$attrs,
      }
      return result
    },

    actualParams() {
      let result = {
        ...this.filter,
        ...this.params,
      }
      return result
    },

    contentSlots() {
      let {row1, row2, ...extraSlots} = this.$slots
      return extraSlots
    }
  },
  
  mounted() {
    this.table = this.$refs['mf-table'].$refs['el-table']
  },
  watch: {
    actualParams: {
      handler: function(current, old) {
        // 如果page不变，则重置page为1
        if (current.page === old.page && current.page !== 1) {
          this.changePage(1)
          return
        }
        let params = this.makeParams()
        if (!isEqual(current, old)) {
          this.fetchData(params)
        }
      },
      deep: true,
    },
  },
  
  methods: {
    formatData(data) {
      if (this.formatFunc) {
        return this.formatFunc(data)
      }
      return data
    },

    makeParams() {
      let params = {...this.actualParams}
      if (this.paramsFunc) {
        return this.paramsFunc(params)
      }
      return this.actualParams
    },
    
    changePage(value) {
      this.filter.page = value
    },

    reset() {
      this.changePage(1)
    },

    getData() {
      return this.sourceData
    },

    handleSizeChange(v) {
      this.filter.pageSize = v
    },
  },

  inheritAttrs: false,
}
</script>

<style lang='scss'>
  .mf-filter-table .cell .el-tag {
    margin-bottom: 0.5em;
    margin-right: 0.5em;
  }
</style>
