<template>
  <el-select 
    v-model="level" 
    placeholder="员工等级"
    @change='change'
    class="select-gender item"
    v-bind="$attrs"
    size='small'
  >
    <el-option-group
      v-for="group in sourceData"
      :key="group.label"
      :label="group.label"
    >
      <el-option
        v-for="item in group.options"
        :key="item.id"
        :label="item.label"
        :value="item.id">
      </el-option>
    </el-option-group>
  </el-select>
</template>

<script>
import { fetchCoachLevels } from '@/api/coach'
import fetch from '@/mixins/fetch'
import { uniq, isString, intersection } from 'lodash'

export default {
  name: 'MfCoachLevelOld',
  
  mixins: [fetch],

  props: {
    value: {
      required: true,
    },
    formatFunc: Function,
    coachType: [Array, String],
    all: {
      type: Boolean,
      default: false,
    },
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  data() {
    return {
      data: null,
      level: this.value,
      fetchApi: fetchCoachLevels,
    }
  },

  watch: {
    value() {
      this.level = this.value
    },
  },

  methods: {
    fetchData(params = {}) {
      this.isLoading = true
      this.fetchApi(params).then(res => {
        this.data = res.data
        this.sourceData = this.formatData(res.data)
        this.isLoading = false
      })
    },

    formatData(data) {
      if (this.formatFunc) {
        return this.formatFunc(data)
      }

      data.map(obj => {
        obj.label = obj.fullName
        if (obj.enName) {
          obj.label = `${obj.name} / ${obj.enName}`
        }
      })
      let category = data.map(obj => obj.categoryDesc)
      category = uniq(category)
      if (this.coachType) {
        category = this.filterCategory(category)
      }
      let result = []
      category.map(value => {
        result.push({
          label: value,
          options: data.filter(obj => obj.categoryDesc === value),
        })
      })
      if (this.all) {
        result.unshift({
          options: [
            {
              label: '全部',
              value: '',
            },
          ],
        })
      }
      return result
    },

    filterCategory(category) {
      let type = [...this.coachType]
      if (!type) {
        return category
      }
      if (isString(type)) {
        type = [type]
      }
      return intersection(type, category)
    },

    change(value) {
      let coach = this.data.find(obj => obj.id === value)
      console.log(coach)
      let { categoryDesc } = coach
      this.$emit('change', value, categoryDesc)
    },
  },

  inheritAttrs: false,
}
</script>

<style lang='scss' scoped>
</style>
