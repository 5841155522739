import Layout from 'views/layout/Layout';

export default {
  path: '/members',
  redirect: '/members/list',
  name: 'members',
  component: Layout,
  title: '会员管理',
  permission: ['member'],
  hidden: false,
  icon: 'member',
  children: [
    {
      path: 'list',
      component: () => import('views/member/List'),
      title: '会员列表',
      keepAlive: true,
      name: 'MemberList',
      permission: ['member', 'list'],
      hidden: false,
      helpDoc: "https://www.yuque.com/ap1q4q/ngd5zk/gpy82z"
    },
    {
      path: 'operating',
      title: '会员运营',
      component: () => import('views/member_operating/Index'),
      name: 'MemberOperating',
      permission: ['member', 'member_operating'],
      hidden: false,
      // helpDoc: "https://www.yuque.com/ap1q4q/ngd5zk/wts1qs"
    },
    {
      path: 'group_operating',
      title: '团课会员',
      // redirect: '/members/group_operating/new_members',
      component: () => import('views/group_training_member_operating/Index'),
      name: 'GroupTrainingMemberOperating',
      permission: ['member', 'group_operating'],
      hidden: false,
      // helpDoc: "https://www.yuque.com/ap1q4q/ngd5zk/wts1qs",
    },
    {
      path: 'elite_member_operating',
      title: '私教会员',
      // redirect: '/members/elite_member_operating/active_members',
      component: () => import('views/elite_member_operating/Index'),
      name: 'EliteMembersOperating',
      permission: ['member', 'elite_operating'],
      hidden: false,
      // helpDoc: "https://www.yuque.com/ap1q4q/ngd5zk/wts1qs",
    },
    {
      path: ':memberId',
      redirect: '/members/:memberId/membership',
      component: () => import('views/member/Detail'),
      name: 'memberDetail',
      title: '会员详情',
      permission: ['member', 'view'],
      helpDoc: "https://www.yuque.com/ap1q4q/ngd5zk/gpy82z",
      children: [
        {
          path: 'membership',
          component: () => import('views/member/detail/Membership'),
          name: 'memberDetailMembership',
          title: '余额统计',
          children: [
            {
              path: 'records',
              component: () => import('views/member/record/Membership'),
              name: 'memberRecordMembership',
              title: '账户使用记录',
              root: true,
            },
          ],
        },
        {
          path: 'training',
          component: () => import('views/member/detail/Training'),
          name: 'memberDetailTraining',
          title: '训练统计'
        },
        {
          path: 'order',
          component: () => import('views/member/detail/Order'),
          name: 'memberDetailOrder',
          title: '消费统计'
        },
        {
          path: 'fitness_test',
          component: () => import('views/member/detail/FitnessTest'),
          name: 'memberDetailFitnessTest',
          title: '体测记录'
        },
        {
          path: 'account_member/list',
          component: () => import('views/member/components/AccountMembers'),
          name: 'membershipAccountMemberList',
          title: '共享卡管理',
          root: true,
        },
      ],
    }
  ],
};
