import Layout from 'views/layout/Layout'

export default {
  path: '/setting',
  redirect: '/setting/gate',
  name: 'setting',
  component: Layout,
  title: '系统管理',
  permission: ['system'],
  hidden: false,
  icon: 'setting',
  children: [
    {
      path: 'partners',
      component: () => import('views/partner/List'),
      title: '合作商户管理',
      keepAlive: true,
      name: 'SettingPartners',
      permission: ['system', 'partners'],
      hidden: false,
    },
    {
      path: 'admins',
      component: () => import('views/setting/admins/Index'),
      title: '帐号管理',
      keepAlive: true,
      name: 'SettingAdmins',
      permission: ['system', 'admins'],
      hidden: false,
      helpDoc: "https://www.yuque.com/ap1q4q/ngd5zk/ghyrv1"
    },
    {
      path: 'roles',
      component: () => import('views/setting/permissions/Index'),
      title: '权限管理',
      keepAlive: true,
      name: 'SettingPermissions',
      permission: ['system', 'roles'],
      hidden: false,
      helpDoc: "https://www.yuque.com/ap1q4q/ngd5zk/xaxsgm"
    },
    {
      path: 'logs',
      component: () => import('views/setting/logs/Index'),
      title: '操作日志',
      keepAlive: true,
      name: 'SettingLogs',
      // permission: ['system', 'roles'],
      hidden: false,
    },
  ],
}
