const app = {
  state: {
    sidebar: {
      opened: localStorage.getItem('sidebarOpened') === 'true',
      withoutAnimation: false
    },
    device: 'desktop',
    cachedViews: []
  },
  mutations: {
    TOGGLE_SIDEBAR: state => {
      if (state.sidebar.opened) {
        localStorage.removeItem('sidebarOpened')
      } else {
        localStorage.setItem('sidebarOpened', true)
      }
      state.sidebar.opened = !state.sidebar.opened
      state.sidebar.withoutAnimation = false
    },
    CLOSE_SIDEBAR: (state, withoutAnimation) => {
      localStorage.removeItem('sidebarOpened')
      state.sidebar.opened = false
      state.sidebar.withoutAnimation = withoutAnimation
    },
    TOGGLE_DEVICE: (state, device) => {
      state.device = device
    },
    ADD_CACHED_VIEW: (state, view) => {
      if (view.meta.keepAlive && state.cachedViews.indexOf(view.name) < 0) {
        state.cachedViews.push(view.name)
      }
    }
  },
  actions: {
    ToggleSideBar: ({ commit }) => {
      commit('TOGGLE_SIDEBAR')
    },
    CloseSideBar({ commit }, { withoutAnimation }) {
      commit('CLOSE_SIDEBAR', withoutAnimation)
    },
    ToggleDevice({ commit }, device) {
      commit('TOGGLE_DEVICE', device)
    },
    AddCachedView({ commit }, view) {
      commit('ADD_CACHED_VIEW', view)
    },
  }
}

export default app
