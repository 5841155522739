<template>
  <el-dialog
    :visible='dialogVisible'
    v-bind="$attrs"
    v-on="$listeners"
    ref='dialog'
    @close='closeDialog'
  >
    <slot></slot>
  </el-dialog>  
</template>

<script>
export default {
  name: 'MfDialog',
  
  props: {
    name: {
      type: String,
      required: true,
    },
    closeByUser: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    dialogVisible() {
      let { name } = this
      return this.$store.getters.dialogVisible(name)
    },
  },

  methods: {
    closeDialog() {
      if (!this.closeByUser) {
        this.$store.commit('CLOSE_DIALOG')
      }
    },

    close() {
      let el = this.$refs.dialog.$el
      el.querySelector('.el-dialog__headerbtn').click()
      this.$emit('close')
    }
  },

  inheritAttrs: false,
}
</script>

<style lang="scss" scoped>
</style>
