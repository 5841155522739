<script>
import { mapState } from 'vuex'
import { getDialogTitle } from './helper'
import SpDialog from './SpDialog.vue'
import SpForm from './SpForm.vue'

export default {
  name: 'SpFormDialog',
  props: {
    operationId: {
      type: String,
      required: true,
    },
    fetchOperationId: {
      type: String,
      default: null
    },
    reloadOperationId: {
      type: String,
    },
    dialogProps: {
      type: Object,
      default: () => {},
    },
    formProps: {
      type: Object,
      default: () => {},
    },
    config: {
      type: Object,
    },
    beforeSubmit: {
      type: Function,
    },
    afterSubmit: {
      type: Function,
    },
    formatResult: {
      type: Function,
    },
    initialValues: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
    },
    tips: {
      type: String,
      default: null
    },
    topTips: {
      type: String,
      default: null
    },
    confirmButtonText: {
      type: String,
      default: '确定'
    },
    confirmText: {
      type: String,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },

  components: {
    SpDialog,
    SpForm,
  },

  data() {
    const { apiVersion="v1" } = this.config
    return {
      dialogTitle: this.title || getDialogTitle(this.operationId, apiVersion),
      isLoading: false,
    }
  },

  computed: {
    ...mapState({
      payload: (state) => state.global.dialogPayload,
    }),

    actualParams() {
      return this.payload[this.actualName]
    },

    actualName() {
      return this.dialogProps?.name || this.operationId
    },
  },

  methods: {
    handleConfirm() {
      const action = () => {
        this.isLoading = true
        this.$refs.spForm
          .handleSubmit()
          .then(() => {
            this.$store.commit('CLOSE_DIALOG')
          })
          .finally(() => {
            this.isLoading = false
          })
      }
      
      if (this.confirmText) {
        this.$confirm(this.confirmText, '确认', {type: 'warning'}).then(action)
      } else {
        action()
      }
    },

    success() {
      this.$emit('success')
    },

    valueChange(value) {
      this.$emit('valueChange', value)
    },

    getValue() {
      return this.$refs.spForm.getValue()
    },

    handleClosed() {
      this.$refs.spForm.reset()
    },

    reload() {
      this.$refs.spForm.reset()
    },
  },

  render() {
    return (
      <SpDialog
        width="580px"
        title={this.dialogTitle}
        isLoading={this.isLoading}
        name={this.actualName}
        confirmButtonText={this.confirmButtonText}
        showFooter={!this.readOnly}
        on-confirm={this.handleConfirm}
        dialogProps={this.dialogProps}
        on-closed={this.handleClosed}
      >
        <SpForm
            ref="spForm"
            showSubmitBtn={false}
            operationId={this.operationId}
            fetchOperationId={this.fetchOperationId}
            reloadOperationId = {this.reloadOperationId}
            params={this.actualParams}
            initialValues={this.initialValues}
            config={this.config}
            formProps={this.formProps}
            scopedSlots={this.$scopedSlots}
            onSuccess={this.success}
            beforeSubmit={this.beforeSubmit}
            afterSubmit={this.afterSubmit}
            formatResult={this.formatResult}
            onValueChange={this.valueChange}
            topTips={this.topTips}
            tips={this.tips}
            readOnly={this.readOnly}
          ></SpForm>
      </SpDialog>
    )
  },
}
</script>

<style lang='scss' scoped>
</style>
