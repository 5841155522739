<template>
  <div :class='{"mf-image-preview": true, "show": visible}'>
    <i class='icon icon-close' @click="handleClose"></i>
    <div class='prev-area' @click='handlePrev' v-if="data && data.length !== 1">
      <i class='icon icon-left prev'></i>
    </div>
    <div class='next-area' @click='handleNext' v-if="data && data.length !== 1">
      <i class='icon icon-right next'></i>
    </div>
    
    <div class='thumb-wrapper' v-if="thumb && thumb.length">
      <img 
        v-for="(pic, i) in actualThumb" 
        :src='pic.url' 
        :key='"thumb" + i' 
        :class='{"thumb-item": true, active: i === current}' 
        @click="handleClick(i)"
      >
    </div>
    
    <i class='icon icon-together loading'></i>
    <div v-if="data && data.length" class='main' :style='{backgroundImage: `url(${currentUrl})`}'></div>
  </div>
</template>

<script>
import { isString } from 'lodash'

export default {
  name: 'MfImagePreview',
  
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    data: Array,
    thumb: Array,
    index: Number,
  },
  
  model: {
    prop: 'visible',
    event: 'change',
  },
  
  data() {
    return {
      current: this.index,
      loaded: true,
    }
  },
  
  computed: {
    actualData() {
      return this.generateArr(this.data)
    },
    
    actualThumb() {
      return this.generateArr(this.thumb)
    },
    
    currentUrl() {
      if (this.data.length) {
        return this.actualData[this.current].url
      }
    },
  },
  
  mounted() {
    this.$nextTick(() => {
      document.body.append(this.$el)
    })
  },
  
  destroyed() {
    this.$el.remove()
  },
  
  watch: {
    index(val) {
      if (val !== this.current) {
        this.current = val
      }
    }
  },
  
  methods: {
    generateArr(arr) {
      if (arr && arr.length && arr.every(isString)) {
        return arr.map(url => ({url}))
      }
      return arr
    },
    
    handleClick(i) {
      this.current = this.checkRange(i)
    },
    
    handlePrev() {
      this.current = this.checkRange(this.current + -1)
    },
    
    handleNext() {
      this.current = this.checkRange(this.current + 1)
    },
    
    handleClose() {
      this.$emit('close', false)
    },
    
    checkRange(i) {
      let max = this.data.length - 1
      let min = 0
      let current = i
      if (current > max) {
        current = min
      } else if (current < min) {
        current = max
      }
      return current
    },
  },
}
</script>

<style lang='scss' scoped>
.mf-image-preview {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  z-index: 1001;
  display: none;
  
  .prev,
  .next {
    color: #ffffff;
    font-size: 50px;
    @include center(y);
    opacity: .6;
    cursor: pointer;
  }
  
  .prev-area {
    position: absolute;
    width: 20%;
    height: 100%;
    left: 0;
    top: 0;
    
    &:hover {
      .icon {
        opacity: 1;
      }
    }
  }
  
  .next-area {
    position: absolute;
    width: 20%;
    height: 100%;
    right: 0;
    top: 0;
    
    &:hover {
      .icon {
        opacity: 1;
      }
    }
  }
  
  .prev {
    left: 50px;
  }
  
  .next {
    right: 50px;
  }
}

.thumb-wrapper {
  position: absolute;
  width: 100%;
  height: 100px;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  
  .thumb-item {
    width: 70px;
    height: 70px;
    border: 1px solid #fff;
    opacity: 0.6;
    cursor: pointer;
    
    &:hover,
    &.active {
      opacity: 1;
    }
    
    & + .thumb-item {
      margin-left: 15px;
    }
  }
}

.main {
  position: absolute;
  height: calc(100% - 200px);
  width: calc(100% - 200px);
  top: 100px;
  left: 100px;
  background: no-repeat center / contain;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  animation: loading 3s infinite ease-in-out;
  color: #ffffff;
  font-size: 50px;
  opacity: 0.8;
}

.icon-close {
  position: absolute;
  font-size: 25px;
  color: #ffffff;
  opacity: 0.6;
  top: 40px;
  right: 40px;
  z-index: 1;
  cursor: pointer;
  
  &:hover {
    opacity: 1;
  }
  
  &:after {
    content: '';
    position: absolute;
    top: -50px;
    left: -50px;
    bottom: -50px;
    right: -50px;
  }
}

@keyframes loading {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  50% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(0);
  }
}
</style>