import Layout from 'views/layout/Layout'

export default {
  path: '/coaches',
  redirect: '/coaches/list',
  component: Layout,
  name: 'coaches',
  title: '教练管理',
  permission: ['coach'],
  icon: 'coach',
  hidden: false,
  children: [
    {
      path: 'coaches',
      component: () => import('views/kid/coach/coach/CoachesList'),
      name: 'coachList',
      title: '教练人事',
      permission: ['coach', 'list'],
      keepAlive: true,
      hidden: false,
    },
    {
      path: 'levels',
      component: () => import('views/kid/coach/level/LevelsList'),
      title: '教练等级',
      permission: ['coach', 'levels'],
      name: 'CoachLevel',
      hidden: false,
    },
    {
      path: 'categories',
      component: () => import('views/kid/coach/level/CategoriesList'),
      title: '教练类型',
      permission: ['coach', 'levels'],
      name: 'CoachLevel',
      hidden: true,
    },
  ],
}
