<template>
  <el-select 
    v-model="tag" 
    placeholder="会员标签"
    @change='change'
  >
    <el-option
      v-for="item in options"
      :key="item"
      :label="item"
      :value="item"
    >
    </el-option>
  </el-select>  
</template>

<script>
export default {
  name: 'MfTags',
  
  props: {
    value: Array,
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  data() {
    return {
      tag: this.value,
    }
  },

  watch: {
    value() {
      this.tag = this.value
    },
  },

  computed: {
    options() {
      return this.$store.getters.tags
    },
  },

  created() {
    this.$store.dispatch('getTags')
  },

  methods: {
    change(tag) {
      this.$emit('change', tag)
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
