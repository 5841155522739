import { onMounted, reactive } from '@vue/composition-api'
import { isNumber, identity, debounce } from 'lodash'

export const useSelect = (context, { defaultIndex, fetchApi, formatResult = identity, manual = false, all = false}) => {
  const state = reactive({
    data: [],
    loading: false,
  })
  const fetchData = async (query) => {
    state.loading = true
    let res = await fetchApi(query)
    const actualData = res?.data ? res.data : res
    if (all) {
      if (actualData) {
        state.data = [{id: null, name: "全部"}].concat(actualData)
      } else {
        state.data = [{id: null, name: "全部"}]
      }
    } else {
      state.data = formatResult(actualData)
    }

    state.loading = false

    if (actualData && actualData.length && isNumber(defaultIndex)) {
      context.emit('change', actualData[0].id)
    }
  }
  const handleSearch = debounce(
    (v) => fetchData(v),
    300
  )
  onMounted(() => {
    if (!manual) {
      fetchData()
    }
  })

  return { state, refresh: fetchData, handleSearch }
}
