import moment from "moment";

export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null
  }
  if (cFormat === undefined) {
    cFormat = 'date'
  }
  if (cFormat === 'date') {
    cFormat = '{y}-{m}-{d}'
  } else if (cFormat === 'dateTime') {
    cFormat = '{y}-{m}-{d} {h}:{i}:{s}'
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (('' + time).length === 10) time = parseInt(time) * 1000
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a')
      return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

export function getCurrentMonth() {
  let date = new Date()
  date.setDate(1)
  let result = date.toISOString(true)
  return result
}

export function getMonth(month) {
  let date = new Date()
  if (month) {
    let m = date.getMonth() + month
    date.setMonth(m)
  }
  date.setDate(1)
  let result = date.toISOString(true)
  return result
}

export function changeDate(date, dir, interval) {
  if (interval === 'week') {
    if (dir === 'prev') {
      let d = moment(date).subtract(7, 'days')
      let from = d.startOf('week').toISOString(true)
      let to = d.endOf('week').toISOString(true)
      return [from, to]
    } else if (dir === 'next') {
      let d = moment(date).add(7, 'days')
      let from = d.startOf('week').toISOString(true)
      let to = d.endOf('week').toISOString(true)
      return [from, to]
    }
  } else if (interval === 'month') {
    if (dir === 'prev') {
      let d = moment(date).subtract(1, 'month')
      let from = d.startOf('month').toISOString(true)
      let to = d.endOf('month').toISOString(true)
      return [from, to]
    } else if (dir === 'next') {
      let d = moment(date).add(1, 'month')
      let from = d.startOf('month').toISOString(true)
      let to = d.endOf('month').toISOString(true)
      return [from, to]
    }
  }
}


// 生成datePicker快速选择日期的数据
export function generateTimeRange(month) {
  if (month === 0) {
    return {
      text: '本月',
      onClick(picker) {
        const start = moment().startOf('month').toDate()
        const end = moment().endOf('month').toDate()
        picker.$emit('pick', [start, end])
      }
    }
  } else {
    const MONTHS = ['一', '', '三', '', '', '六']
    return {
      text: `前${MONTHS[month - 1]}个月`,
      onClick(picker) {
        const start = moment().subtract(month, 'month').startOf('month').toDate()
        const end = moment().subtract(1, 'month').endOf('month').toDate()
        picker.$emit('pick', [start, end])
      }
    }
  }
}

export function formatDuration(duration) {
  console.log(duration, Array.isArray(duration), duration.length === 2)
  if (!Array.isArray(duration) || duration.length !== 2) {
    throw Error('parseDuration: 参数出错')
    return 
  }
  let [from, to] = duration
  if (from && to) {
    from = new Date(from).toISOString(true)
    to = new Date(to).toISOString(true)
  }
  return {from, to}
}

export function formatSecondToTime(second) {
  return [parseInt(second / 60 / 60), parseInt(second / 60) % 60, second % 60].join(":")
    .replace(/\b(\d)\b/g, "0$1")
}

export function formatFrameSecond(second) {
  return `${parseInt(second / 60) % 60}'${second % 60}"`.replace(/\b(\d)\b/g, "0$1")
}
