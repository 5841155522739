<template>
  <mf-dialog
    :name='name'
    :title='title'
  >
    <mf-form
      :parentData='form'
      :config='config'
      :submitFunc='submitData'
    >
      <el-date-picker
        v-if="dateType === 'month'"
        slot='month'
        type="month"
        placeholder="选择月份"
        v-model='form.month'
        size='small'
      />
      <mf-date-picker
        v-else
        slot='from'
        v-model="durationDate"
      />
    </mf-form>
  </mf-dialog>
</template>

<script>
import MfDialog from '@/components/MfDialog'
import MfForm from '@/components/MfForm'
import moment from 'moment'
import { downloadFile } from '@/utils/'

export default {
  name: 'MfExportDialog',
  
  props: {
    submitFunc: {
      type: Function,
      required: true,
    },
    submitLoading: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    title: String,
    duration: {
      type: Array,
      default: _ => [moment().startOf('month').toISOString(true), moment().endOf('month').toISOString(true)]
    },
    dateType: {
      type: String,
      default: 'duration'
    },
    customConfig: Array,
  },

  components: {
    MfDialog,
    MfForm,
  },

  data() {
    let month = [ { label: '选择时间', prop: 'form', slot: 'month', required: true } ]
    let duration = [ { label: '选择时间', prop: 'form', slot: 'from', required: true } ]
    let itemConfig = this.dateType === 'month' ? month : duration
    if (this.customConfig) {
      itemConfig = [
        ...itemConfig,
        ...this.customConfig,
      ]
    }
    return {
      form: {
        from: this.duration[0],
        to: this.duration[1],
        month: null,
      },
      config: {
        submitText: '提交',
        itemConfig,
      },
    }
  },
  
  computed: {
    durationDate: {
      get: function() {
        return [this.form.from, this.form.to]
      },
      set: function([from, to]) {
        this.form.from = from
        this.form.to = to
      },
    },
  },
  
  watch: {
    duration([from, to]) {
      this.form.from = from
      this.form.to = to
    },
  },
  
  methods: {
    submitData(data) {
      let durationTime = `${moment(this.form.from).format('YYYY/MM/DD')} - ${moment(this.form.to).format('YYYY/MM/DD')}`
      let monthTime = moment(this.form.month).format('YYYY/MM/DD')
      let date = this.dateType === 'month' ? monthTime : durationTime
      return this.submitFunc(data, date)
    },
  },
}
</script>

<style lang='scss' scoped>
/deep/ .el-date-editor {
  width: 100%;
}
</style>
