<template>
  <el-select 
    v-model="studioId" 
    placeholder="门店选择"
    size='small'
    class="item"
    :clearable="true"
    :multiple="multiple"
  >
    <el-option
      v-for="item in options"
      :key="item.id"
      :label="item.name"
      :value="item.id">
    </el-option>
  </el-select>
</template>

<script>
import { API } from '@/services/brand'
import fetch from '@/mixins/fetch'

export default {
  name: 'MfStudios',
  
  mixins: [fetch],
  
  props: {
    grantedOnly: {
      type: Boolean,
      default: false,
    },
    value: null,
    all: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    params: Object,
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  data() {
    return {
      studioId: this.value,
      fetchApi: params => API.studioStudioList({grantedOnly: this.grantedOnly}),
    }
  },

  computed: {
    options() {
      if (!this.sourceData) {
        return
      }
      let result = this.sourceData
      if (this.all && !this.multiple) {
        result = [{
          id: null, 
          name: '全部',
        }, ...result]
      }
      return result
    },
  },
  
  watch: {
    studioId(val) {
      this.change(val)
    },
    value() {
      this.studioId = this.value
    }
  },
  
  methods: {
    change(value) {
      this.$emit('change', value)
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
