<template>
  <div class='form-list'>
    <el-button size="small" class="item btn-add" @click='handleAdd'>
      <i class="icon icon-add"></i> 添加
    </el-button>
    <template v-for="(list, j) in lists">
      <div
        class="fields-group"
        v-for="(item, i) in actualConfig"
        :key='"fields" + j + i'
      >
        <el-form-item 
          v-if="item.slot"
          :key='"form-item" + i'
          :label="item.label"
          :prop='item.slot'
        >
          <slot :name="item.slot"></slot>
        </el-form-item>
        <el-form-item 
          v-else-if="item.component"
          :key='"form-item" + i'
          :label="item.label"
          :prop='item.prop'
        >
          <component 
            :is="item.component"
            v-model='list[item.prop]'
            v-bind="item.propsData"
          >
            <template slot="prepend" v-if="$get(item, 'propsData.prepend')">{{$get(item, 'propsData.prepend')}}</template>
            <template slot="append" v-if="$get(item, 'propsData.append')">{{$get(item, 'propsData.append')}}</template>
          </component>
        </el-form-item>
        
        <el-form-item 
          :label='item.label' 
          :prop='item.prop'
          :key='"form-item" + i'
          v-else
        >
          <el-input 
            v-if="item.itemType === 'input'"
            v-model="list[item.prop]"
            v-bind="item.propsData"
          />
          <template v-if="item.itemType === 'select'" >
            <el-select
              v-model='list[item.prop]'
              v-bind="item.propsData"
            >
              <el-option
                v-for="option in item.propsData.data"
                :key="option[item.valueKey || 'value']"
                :label="option[item.labelKey || 'label']"
                :value="option[item.valueKey || 'value']"
              ></el-option>
            </el-select>
          </template>
        </el-form-item>

        <div v-if='item.tips' :style='{marginLeft: formConfig.labelWidth}' :key='"tips" + i'>
          <div class='tips' v-if='isString(item.tips)'>{{item.tips}}</div>
          <div class="tips" v-if="isFunction(item.tips)">{{item.tips(actualForm)}}</div>
          <slot :name='item.tips.slot' v-else></slot>
        </div>
      </div>

      <div class="delete-button" slot='delete' :key='"fields" + j' v-if="onRemove() !== false">
        <i class="icon icon-delete" @click='handleDelete(j)'></i>
      </div>
    </template>
  </div>
</template>

<script>
import { isEmpty, isString, isArray, cloneDeep, isFunction, isEqual, isObject } from 'lodash'

export default {
  name: 'MfFormList',
  props: {
    config: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      default: () => [],
    },
    onRemove: {
      type: Function,
      default: () => false,
    },
  },

  model: {
    prop: 'data',
    event: 'change',
  },

  data() {
    return {
      lists: this.data,
    }
  },

  computed: {
    actualConfig() {
      let config = cloneDeep(this.config)
      for (const key in config) {
        if (config.hasOwnProperty(key)) {
          const item = config[key]
          if (item.hasOwnProperty('required') && item.required) {
            let prop = item.prop || item.slot
            let rule = [{required: true, trigger: 'blur', message: `${item.label}不能为空`}]
            if (config.hasOwnProperty('rules')) {
              if (!config.rules.hasOwnProperty(item.prop)) {
                config.rules[prop] = rule
              }
            } else {
              config.rules = {}
              config.rules[prop] = rule
            }
          }
        }
      }
      config.forEach(item => {
        if (!item.slot && !item.component && !item.itemType) {
          item.component = 'el-input'
        }
      })
      return config
    },
  },

  watch: {
    lists: {
      deep: true,
      handler: function(val) {
        this.$emit('change', val)
      }
    },

    data: {
      deep: true,
      handler: function(val) {
        if (!isEqual(val, this.lists)) {
          this.lists = val
        }
      }
    },
  },

  methods: {
    handleAdd() {
      this.lists.push(this.genGroupData())
    },

    handleDelete(i) {
      let removeItem = this.lists.splice(i, 1)
      this.$emit('remove', removeItem[0])
      this.onRemove(removeItem[0])
    },

    genGroupData() {
      let config = this.config
      let form = {}
      config.forEach(obj => {
        let value = obj.defaultValue !== null ? cloneDeep(obj.defaultValue) : null
        form[obj.prop] = value
      })
      return form
    },

    isString,

    isFunction,

    isObject,
  },
}
</script>

<style lang='scss' scoped>
.delete-button {
  color: $primary;
  padding-left: 480px;
  margin-bottom: 20px;
  cursor: pointer;
}

.fields-group {
  & + .fields-group {
    margin-top: 18px;
  }
}

.btn-add + .fields-group{
  margin-top: 20px;
}
</style>
