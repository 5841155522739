import Layout from 'views/layout/Layout'
import { get } from "lodash";

export default {
  path: '/products',
  redirect: '/products/list',
  component: Layout,
  name: 'products',
  title: '训练产品',
  hidden: false,
  icon: 'grouptraining',
  permission: ['course_product'],
  children: [
    {
      path: 'list',
      component: () => import('views/products/Index'),
      name: 'productsList',
      title: '训练产品',
      permission: ['course_product', 'list'],
      hidden: false,
      helpDoc: "https://www.yuque.com/ap1q4q/ngd5zk/nd92vi"
    },
    {
      path: 'academy',
      component: () => import('views/academy/products/List'),
      name: 'academyProducts',
      title: '教练培训',
      hidden: false,
      permission: ['course_product', 'academy'],
      helpDoc: "https://www.yuque.com/ap1q4q/ngd5zk/abzzxo",
      children: [
        {
          path: 'products/:productId/detail',
          component: () => import('views/academy/products/Detail'),
          name: 'academyProductsDetail',
          title: '团课产品详情',
          permission: ['course_product', 'academy'],
          root: true
        },
      ]
    },
    {
      path: 'exercises',
      component: () => import('views/tas/exercises/Index'),
      title: '动作库',
      keepAlive: true,
      name: 'ExerciseIndex',
      permission: ['course_product', 'exercises'],
      hidden: false,
    },
    {
      path: ':productId/workout/:workoutId',
      component: () => import('views/tas/workouts/Detail'),
      title: '产品详情',
      keepAlive: true,
      name: 'ProductDetail',
      permission: ['course_product', 'list'],
      hidden: true,
      children: [
        {
          path: 'workout_outlines/:workoutOutlineId',
          component: () => import('views/tas/outlines/Detail'),
          name: 'OutlineShow',
          title: '套路详情',
          hidden: true,
          root: true,
        },
      ]
    },
    // {
    //   path: ':productId/workout_outlines',
    //   component: () => import('views/tas/outlines/Index'),
    //   title: '课程套路',
    //   keepAlive: true,
    //   name: 'OutlineIndex',
    //   permission: ['course_product', 'list'],
    //   hidden: true,
    //   children: [
    //     {
    //       path: ':workoutOutlineId',
    //       component: () => import('views/tas/outlines/Show'),
    //       name: 'OutlineShow',
    //       title: '编辑套路',
    //       hidden: true,
    //       root: true,
    //     },
    //   ]
    // },
    // {
    //   path: 'new',
    //   component: () => import('views/products/New'),
    //   name: 'productsNew',
    //   title: '添加课程',
    // },
    // {
    //   path: 'edit',
    //   component: () => import('views/products/Edit'),
    //   name: 'productsNew',
    //   title: '添加课程',
    // },

  ]
}
