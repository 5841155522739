import Layout from 'views/layout/Layout';

export default {
  path: '/accounts',
  redirect: '/accounts/prepay_accounts',
  name: 'accounts',
  component: Layout,
  title: '账户管理',
  permission: ['account'],
  hidden: false,
  icon: 'account',
  children: [
    {
      path: 'prepay_accounts',
      component: () => import('views/membership_operating/Prepay'),
      title: '储值卡账户',
      keepAlive: true,
      name: 'PrepayIndex',
      permission: ['account', 'prepay_accounts'],
      hidden: false,
    },
    {
      path: 'other_accounts',
      component: () => import('views/membership_operating/OtherAccounts'),
      title: '会员卡账户',
      keepAlive: true,
      name: 'OtherAccountsList',
      permission: ['account', 'other_accounts'],
      hidden: false,
    },
  ],
};
