<template>
  <el-select
    :size="size"
    v-model="type"
    placeholder="产品类型"
    class="item"
    @change="change"
  >
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    >
    </el-option>
  </el-select>
</template>

<script>
import {PRODUCT_TYPES} from "@/constant/options"
export default {
  name: "SpProductType",

  props: {
    value: null,
    size: {
      type: String,
      default: "small",
    },
    productTypes: {
      type: Array,
      default: () => PRODUCT_TYPES
    },
    all: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      type: this.value,
    };
  },

  model: {
    prop: "value",
    event: "change",
  },

  computed: {
    options() {
      let options = this.productTypes;
      if (this.all) {
        options = [
          {
            label: "全部产品",
            value: null,
          },
          ...options,
        ];
      }
      return options;
    },
  },

  methods: {
    change(value) {
      this.$emit("change", value);
    },
  },
};

// let PRODUCT_TYPES = [
//   {
//     name: "团课",
//     value: "group_training",
//   },
//   {
//     name: "私教",
//     value: "elite_training",
//   },
//   {
//     name: "训练营",
//     value: "camp",
//   }
// ];
</script>

<style lang="scss" scoped>
</style>
