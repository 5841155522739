<template>
  <div class="mf-tags">
    <el-tag 
      size='mini'
      v-for="(tag, i) in showData"
      :key='"tag" + i'
      :disable-transitions='true'
      closable
      :type="types[i%5]"
      @close="handleDelete(i)"
    >{{tag}}</el-tag>
    <el-input
      class="input-new-tag"
      v-if="inputVisible"
      v-model="inputValue"
      ref="saveTagInput"
      size="mini"
      @keyup.enter.native="handleInputConfirm"
      @blur="handleInputConfirm"
    />
    <el-button 
      v-else
      class="button-new-tag" 
      size="mini" 
      @click="showInput"
    >+ 新增标签</el-button>
  </div>
</template>

<script>
import fetch from '@/mixins/fetch'

export default {
  name: 'MfTagList',
  
  mixins: [fetch],
  
  props: {
    value: {
      type: null,
      default: _ => [],
    },
    color: {
      type: Array,
      default: _ => ['success', 'warning', 'danger', '', 'info']
    },
    showProperty: String,
    addItemFunc: Function,
    deleteItemFunc: Function,
    fetchApi: {
      type: Function,
      required: true,
    },
  },
  
  model: {
    prop: 'value',
    event: 'change',
  },

  data() {
    return {
      inputVisible: false,
      inputValue: '',
      types: this.color,
    }
  },
  
  computed: {
    showData() {
      if (!this.sourceData) {
        return []
      }
      let iterate = this.showProperty ? this.iterateFunc : (v => v)
      return this.sourceData.map(iterate)
    },
  },
  
  watch: {
    
  },
  
  methods: {
    handleDelete(i) {
      let deleteItem = this.sourceData[i]
      if (this.deleteItemFunc) {
        this.deleteItemFunc(deleteItem).then(res => {
          this.$message({
            message: '删除标签成功',
            type: 'success',
          })
          this.sourceData.splice(i, 1)
          this.$emit('deleteItem', deleteItem)
          this.fetchData()
        })
      } else {
        this.sourceData.splice(i, 1)
        this.$emit('deleteItem', deleteItem)
      }
    },

    showInput() {
      this.inputVisible = true
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },

    handleInputConfirm() {
      let { inputValue } = this
      if (inputValue) {
        let value = this.showProperty ? this.constructFunc(inputValue) : inputValue
        if (this.addItemFunc) {
          this.addItemFunc(value).then(res => {
            this.sourceData.push(value)
            this.$emit('addItem', inputValue)
            this.$message({
              message: '新增标签成功',
              type: 'success',
            })
            this.fetchData()
          })
          this.inputVisible = false
          this.inputValue = ''
        } else {
          this.sourceData.push(value)
          this.$emit('addItem', inputValue)
          this.inputVisible = false
          this.inputValue = ''
        }
      }
    },

    iterateFunc(o) {
      return o[this.showProperty]
    },

    constructFunc(v) {
      return {
        [this.showProperty]: v,
      }
    }
  },
}
</script>

<style lang='scss' scoped>
.input-new-tag {
  width: 76px;
  margin-left: 4px;
  vertical-align: bottom;

  /deep/ input {
    height: 20px;
    line-height: 18px;
    padding: 0 4px;
  }
}

/deep/ .el-tag {
  margin-right: 8px;
  margin-bottom: 8px;

  & + .input-new-tag,
  & + .button-new-tag {
    margin-left: -4px;
  }
}

.button-new-tag {
  height: 20px;
  line-height: 18px;
  padding: 0 8px;
}

.button-new-tag,
.input-new-tag {
  margin-bottom: 8px;
}
</style>