<template>
  <router-view></router-view>
</template>

<script>
export default {
  data() {
    return {
      
    }
  },
  
  methods: {
    
  },
}
</script>

<style lang='scss' scoped>
div {
  // min-height: 95vh;
}
</style>