import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import store from 'store'
import { isObject } from 'lodash'
import { getStorage } from '@/utils-future/storage'

// 创建axios实例
const service = axios.create({
  baseURL: process.env.BASE_API, // api的base_url
  // timeout: 5000 // 请求超时时间
  // TODO:  - by secret
  timeout: 1000000, // 请求超时时间
  headers: { _errorMessage: '' }, // 请求错误时是否要显示错误信息的message
})

// request拦截器
service.interceptors.request.use(config => {
  console.error("请使用新版 API client:" + config.url)

  const token = getStorage('token')

  if (token) {
    config.headers.authorization = token // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  let { data, params } = config
  if (!data) {
    return config
  }
  let msg = getErrorMessage(data || params)
  if (msg) {
    config.headers._errorMessage = msg
  }
  return config
}, error => {
  // Do something with request error
  console.log(error) // for debug
  Promise.reject(error)
})

// response拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    response['__legacy'] = true
    if (response.status >= 400) {
      Message({
        message: res.message,
        type: 'error',
        duration: 5 * 1000
      })

      if (response.status == 401) {
        MessageBox.confirm('你已被登出，可以取消继续留在该页面，或者重新登录', '确定登出', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          store.dispatch('LogOut').then(() => {
            location.reload()// 为了重新实例化vue-router对象 避免bug
          })
        })
      }
      return Promise.reject('error')
    } else {
      return response
    }
  },
  error => {
    let err = error.response.data
    let { _errorMessage: showError } = error.config.headers
    console.log(err, error.response)
    let msg = err
    if (showError === 'form') {
      if (error.response.data) {
        msg = `请修复表单中的错误: ${Object.keys(err)[0]} ${err[Object.keys(err)[0]]}`
      } else {
        msg = '可能是表单验证出现了错误，请联系技术部的同事'
      }
    }
    if (err.hasOwnProperty('message')) {
      msg = `${err.message}, status: ${error.response.status}`
    } else if (error.response.status >= 500) {
      msg = `服务器内部错误，请联系技术部的同事`
    }
    Message({
      message: msg,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

function getErrorMessage(data) {
  let msg = ''
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const value = data[key]
      if (isObject(value) && value.hasOwnProperty('_errorMessage')) {
        msg = value._errorMessage
        delete value._errorMessage
      }
      if (key === '_errorMessage') {
        msg = value
        delete data._errorMessage
      }
    }
  }
  return msg
}

export default service
