<template>
  <mf-dialog :title="title" :name='dialogName' @open='fetchResource' @closed='resetForm'>
    <mf-form
      :config='formConfig'
      :loading='loading'
      :parentData='{[ACTION_TYPE_KEY]: actionType}'
      @submit='submitForm'
      ref='form'>
    </mf-form>
  </mf-dialog>
</template>

<script>
import resource_form_dialog_mixin from "@/mixins/resource_form_dialog"

export default {  
  name: 'MfResourceFormDialog',

  mixins: [resource_form_dialog_mixin],

  props: {
    id: Number,
    resource: String,
    resourceName: String,
    config: {
      type: Object,
    },
  },
}
</script>