import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import store from 'store'
import { isObject } from 'lodash'
import { getStorage } from '@/utils-future/storage'

// 创建axios实例
const service = axios.create({
  baseURL: process.env.BASE_API_FUTURE, // api的base_url
  // timeout: 5000 // 请求超时时间
  // TODO:  - by secret
  timeout: 1000000, // 请求超时时间
  headers: { _errorMessage: '' }, // 请求错误时是否要显示错误信息的message
})

// request拦截器
service.interceptors.request.use(config => {
  const token = getStorage('token')

  if (token) {
    config.headers.authorization = token // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  
  if (config.version === 'v2') {
    if (!config.headers['x-is-public']) {
      const identifier = getStorage('identifier') || 'zishi'
      config.headers['BrandIdentifier'] = identifier
    }    
  } else {
    if (!config.headers['x-is-public']) {
      const identifier = getStorage('identifier') || 'zishi'
      config.baseURL += `/brand/${identifier}`
    }
  }

  return config
}, error => {
  console.log(error) // for debug
  Promise.reject(error)
})

// response拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    if (response.status >= 400) {
      let message = `<span>${res.message}</span>`
      if (res.hasOwnProperty('errorMessages') && res.errorMessages.length > 0) {
        let detail = "<ul>"
        res.errorMessages.forEach(e => {
          detail += `<li>${e}</li>`
        });
        detail += "</ul>"
        message += detail
      }
      Message({
        message: message,
        dangerouslyUseHTMLString: true,
        type: 'error',
        showClose: true
      })

      if (response.status == 401) {
        MessageBox.confirm('你已被登出，可以取消继续留在该页面，或者重新登录', '确定登出', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          store.dispatch('LogOut').then(() => {
            location.reload()// 为了重新实例化vue-router对象 避免bug
          })
        })
      }
      return Promise.reject('error')
    } else {
      return res
    }
  },
  error => {
    let err = error.response.data
    let msg = err

    if (err.hasOwnProperty('message')) {
      msg = `<span>${err.message}</span>`
    } else if (error.response.status >= 500) {
      msg = `<span>${err.message}</span>`
    }

    if (err.hasOwnProperty('errorMessages') && err.errorMessages.length > 0) {
      let detail = "<ul>"
      err.errorMessages.forEach(e => {
        detail += `<li>${e}</li>`
      });
      detail += "</ul>"
      msg += detail
    }

    Message({
      message: msg,
      dangerouslyUseHTMLString: true,
      type: 'error',
      showClose: true
    })
    return Promise.reject(error)
  }
)


export default service
