
export default {

  data() {
    return {
      dialogVisible: false,
    }
  },

  watch: {
  },

  methods: {
    openDialog() {
      this.dialogVisible = true
    },

    closeDialog() {
      this.dialogVisible = false
      this.$emit('closed')
    },
  }
}
