import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import route from './modules/route'
import user from './modules/user'
import member from './modules/member'
import global from './modules/global'
import getters from './getters'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    route,
    user,
    member,
    global,
  },
  getters
})

export default store
