<template>
  <el-select
    v-model="userId"
    class="item"
    remote
    filterable
    :remote-method="fetchMembersList"
    :loading="isLoading"
    :placeholder="placeholder"
  >
    <el-option
      v-for="item in sourceData"
      :key="item.id"
      :label="item.label"
      :value="item.id">
    </el-option>
  </el-select>
</template>

<script>
import { API } from '@/services/brand'
import fetch from '@/mixins/fetch'

export default {
  name: 'MfMember',
  
  props: {
    value: null,
    placeholder: {
      type: String,
      default: '请输入会员名称',
    },
    formatFunc: Function,
  },
  
  mixins: [fetch],
  
  model: {
    event: 'change',
    prop: 'value',
  },
  
  data() {
    return {
      userId: this.value,
      fetchApi: API.memberMemberList,
    }
  },
  
  watch: {
    userId: function() {
      this.$emit('change', this.userId)
    },

    value: function(val) {
      this.userId = val
    }
  },
  
  methods: {
    fetchMembersList(query) {
      let params = this.makeParams(query)
      this.fetchData(params)
    },
    
    formatData(data) {
      data.map(item => {
        if (item.phone) {
          item.label = `${item.name} / ${item.phone}`
        } else {
          item.label = item.name
        }
      })

      if (this.formatFunc) {
        return this.formatFunc(data)
      }

      return data
    },
    
    makeParams(query) {
      return {
        version: 'essential',
        active: true,
        query,
      }
    }
  },
}
</script>

<style lang='scss' scoped>

</style>
