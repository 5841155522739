import Layout from 'views/layout/Layout'
import RouterView from 'views/layout/RouterView'

export default {
  path: '/academy',
  redirect: '/academy/tutorials/group_training/list',
  name: 'academy',
  component: Layout,
  title: '教练培训',
  hidden: false,
  icon: 'sct',
  permission: ['academy'],
  helpDoc: "https://www.yuque.com/ap1q4q/ngd5zk/abzzxo",
  children: [
    {
      path: 'products',
      component: () => import('views/academy/products/List'),
      name: 'academyProducts',
      title: '团课产品',
      hidden: false,
      permission: ['academy', 'products'],
    },
    {
      path: 'products/:productId/detail',
      component: () => import('views/academy/products/Detail'),
      name: 'academyProductsDetail',
      title: '团课产品详情',
      permission: ['academy', 'products'],
    },
  ],
}
