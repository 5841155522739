<template>
  <div class="mf-radio">
    <el-radio
      v-model="current"
      v-for="(item, i) in actualData"
      :key="item.label + i"
      :label="item.value"
      :disabled="disabled"
      @change="handleChange"
    >{{item.label}}</el-radio>
  </div>
</template>

<script>
export default {
  name: 'MfRadio',
  
  props: {
    data: {
      type: Array,
    },
    value: null,
    type: {
      validator: value => {
        let arr = ['active']
        let bool = arr.includes(value)
        if (!bool) {
          throw Error(`❌type只能为${arr.join('、')}中的一种。`)
        } else {
          return true
        }
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  data() {
    return {
      current: this.value,
    };
  },
  
  computed: {
    actualData() {
      if (this.type === 'active') {
        return activeData
      } else {
        return this.data
      }
    },
  },
  
  watch: {
    value(val) {
      if (val !== this.current) {
        this.current = val
      }
    }
  },

  methods: {
    handleChange(val) {
      this.$emit('change', val)
    }
  }
}

const activeData = [
  { label: '开启', value: true },
  { label: '关闭', value: false },
]
</script>

<style lang='scss' scoped>
</style>