import Layout from 'views/layout/Layout'

export default {
  path: '/finance',
  redirect: '/finance/sales',
  name: 'finance',
  component: Layout,
  title: '财务报表',
  hidden: false,
  icon: 'finance',
  permission: ['finance'],
  children: [
    {
      path: 'orders',
      component: () => import('views/kid/finance/Orders'),
      // component: () => import('views/ComingSoon'),
      name: 'financeOrders',
      title: '销售报表',
      hidden: false,
      permission: ['finance', 'orders'],
    },
    {
      path: 'studios',
      component: () => import('views/kid/finance/Studios'),
      title: '收入报表',
      keepAlive: true,
      name: 'financeStudios',
      permission: ['finance', 'studios'],
      hidden: false,
    },
    {
      path: 'coaches',
      component: () => import('views/kid/finance/Coaches'),
      name: 'financeCoaches',
      title: '教练报表',
      hidden: false,
      permission: ['finance', 'coaches'],
    },
    {
      path: 'debts',
      component: () => import('views/kid/finance/Debts'),
      name: 'financeDebts',
      title: '负债报表',
      hidden: false,
      permission: ['finance', 'debts'],
    },
  ],
}
