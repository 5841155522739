<template>
  <div id="app">
    <div id='detect-adblock' class="google-ad">123321</div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',

  mounted() {
    let el = document.querySelector('#detect-adblock')
    if (el.offsetWidth === 0) {
      console.log('开启了adblock')
      this.$notify({
        title: '警告',
        message: '你开启了adblock或类似的插件，会导致页面显示不正确，请将此网站加入白名单中!',
        duration: 0,
        type: 'error',
        showClose: false,
      })
    }
  },
}
</script>

<style lang="scss" scoped>
#detect-adblock {
  position: absolute;
  color: transparent;
  font-size: 20px;
}
</style>

