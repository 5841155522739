import Layout from 'views/layout/Layout'
import { get } from "lodash"

export default {
  path: '/courses',
  redirect: '/courses/scheduler',
  component: Layout,
  name: 'courses',
  title: '课程运营',
  hidden: false,
  icon: 'calendar',
  permission: ['course'],
  children: [
    {
      path: 'bookings',
      component: () => import('views/course-operating/booking/Index'),
      name: 'courseBookings',
      title: '预约管理',
      permission: ['course', 'bookings'],
      hidden: false,
      helpDoc: 'https://www.yuque.com/ap1q4q/ngd5zk/kkz71v'
    },
    {
      path: 'scheduler',
      component: () => import('views/course-operating/schedule/Index'),
      name: 'coursesScheduler',
      title: '排课管理',
      permission: ['course', 'schedule'],
      hidden: false,
      helpDoc: 'https://www.yuque.com/ap1q4q/ngd5zk/gy9exu'
    },
    {
      path: 'camp',
      component: () => import('views/camp/List'),
      name: 'courseCamp',
      title: '训练营运营',
      permission: ['course', 'camp'],
      helpDoc: "https://www.yuque.com/ap1q4q/ngd5zk/mc7n94",
      hidden: false,
    },
  ]
}
