import { isObject, isArray, isEmpty, isNumber, cloneDeep, isString, get } from 'lodash'

export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (('' + time).length === 10) time = parseInt(time) * 1000
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a')
      return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

export function formatTime(time, option) {
  time = +time * 1000
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

export function parseUrl(url) {
  if (!url) {
    return {}
  }
  let a = document.createElement('a')
  a.href = url
  let search = a.search

  let ret = {}
  search = search.slice(1).split('&')
  for (let i = 0, arr; i < search.length; i++) {
    arr = search[i].split('=')
    let key = arr[0],
      value = arr[1]
    if (/\[\]$/.test(key)) {
      ret[key] = ret[key] || []
      ret[key].push(value)
    } else {
      ret[key] = value
    }
  }
  return ret
}

export function parseStudio(studio) {
  return studio.replace('SPEEDPLAY·', '')
}

export function addAllOption(data, type) {
  let all = { value: '', name: '全部' }
  if (type === 'array') {
    all = '全部'
  }
  return [all, ...data]
}

// 该方法有bug，已弃用
export function filterFalsy(obj, keys) {
  let result = {}
  if (!isArray(keys) && keys !== undefined) {
    throw Error('filterFalsy方法参数错误，必须为字符串数组')
  }
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key]
      if (keys !== undefined && keys.includes(key)) {
        result[key] = value
        continue
      }
      if (
        (isArray(value) && isEmpty(value)) ||
        (isObject(value) && isEmpty(value))
      ) {
        continue
      }
      if (value || value === false) {
        result[key] = value
      }
    }
  }
  return result
}

export function injectDefaultValue(config, data, parentData) {
  // TODO: 增加url的特殊处理 - by secret 👀
  config = cloneDeep(config)
  let keys = Object.keys(data)
  config.itemConfig.forEach(obj => {
    let key = obj.prop
    if (keys.includes(key)) {
      if (obj.prop && !obj.slot) {
        obj.defaultValue = data[key]
      }
    }
    if (obj.slot && parentData) {
      parentData[obj.slot] = data[obj.slot]
    }
  })
  return config
}

export const contains = (root, n) => {
  let node = n
  while (node) {
    if (node === root) {
      return true
    }
    node = node.parentNode
  }
  return false
}

export const findParentsByTag = (n, tag) => {
  let node = n
  while (node && node.tagName.toLowerCase() !== 'body') {
    if (node.tagName.toLowerCase() === tag) {
      return node
    }
    node = node.parentNode
  }
  return null
}

export const downloadFile = (data, fileName, type = 'xlsx') => {
  const typeMaps = {
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    jpeg: 'image/jpeg'
  }

  var blob = new Blob([data], { type: typeMaps.type })

  // create download link
  let a = document.createElement('a')
  a.style = 'display: none'
  document.body.appendChild(a)
  let url = window.URL.createObjectURL(blob)
  a.href = url
  a.download = `${fileName}.${type}`

  // trigger download
  a.click()
  window.URL.revokeObjectURL(url)
  a.remove()
}

export let configWrapper = (config) => {
  if (!isObject(config)) {
    throw Error('表单的config必须是对象')
  } else if (!config.hasOwnProperty('itemConfig')) {
    throw Error('表单的config中没有itemConfig字段')
  }

  class Wrapper {
    constructor(config) {
      this.config = cloneDeep(config)
      this.sourceConfig = cloneDeep(config)
      this.sourceData = null
    }

    async fetch(fetchApi, cb) {
      if (!fetchApi) {
        return
      }
      await fetchApi().then(res => {
        this.sourceData = res.data
        cb(res.data)
      }).catch(_ => {
        throw Error('something wrong')
      })
    }

    find(prop, source = false) {
      if (!isString(prop)) {
        throw Error('find的参数只能是字符串')
      }
      let config = source ? this.sourceConfig : this.config
      let item = config.itemConfig.find(obj => {
        return obj.prop === prop || obj.slot === prop
      })
      if (!item) {
        return
      } else {
        return item
      }
    }

    injectParams(prop, payload) {
      if (!isString(prop)) {
        throw Error('injectParams的prop参数只能是字符串')
      }
      let item = this.find(prop)
      let sourceItem = this.find(prop, true)
      if (!item.hasOwnProperty('renderIf')) {
        throw Error('对应的item没有renderIf方法')
      }
      let func = sourceItem.renderIf
      item.renderIf = data => func(data, payload)
      return this
    }

    add(item, index) {
      if (!isObject(item) || !isNumber(index)) {
        throw Error('add的参数有误，请检查')
      }
      this.config.itemConfig.splice(index, 0, item)
      return this
    }

    delete(prop) {
      if (!isString(prop)) {
        throw Error('find的参数只能是字符串')
      }
      let index = this.config.itemConfig.findIndex(obj => {
        return obj.prop === prop || obj.slot === prop
      })
      if (index === -1) {
        return
      } else {
        return this.config.itemConfig.splice(index, 1)[0]
      }
    }

    inject(data, parentData, omit) {
      if (data === undefined && this.sourceData) {
        data = this.sourceData
      }
      if (!isObject(data)) {
        throw Error('inject的参数必须是对象')
      }
      if (omit && typeof omit === 'string') {
        omit = [omit]
      }
      if (omit && !isArray(omit)) {
        throw Error('inject的omit参数必须为字符串或者数组')
      }
      let dataKeys = Object.keys(data)
      let parentDataKeys = parentData ? Object.keys(parentData) : null
      this.config.itemConfig.map(obj => {
        let key = obj.prop || obj.slot
        if (!(omit && omit.includes(key))) {
          if (dataKeys.includes(key)) {
            if (obj.prop && !obj.hasOwnProperty('slot')) {
              obj.defaultValue = data[key]
            }
          }
          if (parentData && parentDataKeys.includes(key)) {
            parentData[key] = data[key]
          }
        }
      })
      return this
    }

    get() {
      return this.config
    }
  }

  let wrapper = new Wrapper(config)
  return wrapper
}

export let tap = (x, fn = x => x) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(fn(x))
  }
  return x
}

export let validateUrl = (url) => {
  return /^https?/g.test(url)
}

export let urlValidator = (rule, value, cb) => {
  if (validateUrl(value) || value === null || value === undefined || value === '') {
    cb()
  } else {
    cb(Error('url地址前必须有 http:// 或者 https://'))
  }
}

export let injectParams = (config, method, params) => {
  let copyConfig = cloneDeep(config)
  copyConfig.forEach(o => {
    if (o[method]) {
      let func = o[method]
      o[method] = (...args) => {
        return func(...args, params)
      }
    }
  })
  return copyConfig
}


export let getLabelFromType = (value, type) => {
  let o = type.find(o => o.value === value)
  if (!o) {
    return value
    // throw Error(`没有找到${value}对应的 Type`)
  } else {
    return o.label
  }
}
