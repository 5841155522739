<template>
  <el-select :multiple="multiple" :value='value' @change="v => $emit('change', v)" class="item" remote filterable
             :placeholder="placeholder" size="small" clearable>
    <el-option v-for="item in data" :key="item.id" :label="item.name" :value="item.id">
    </el-option>
  </el-select>
</template>

<script>
import { API } from '@/services/brand.js'
import { noop } from '../../utils-future/functional.js'
import { onMounted, reactive, ref, toRefs } from '@vue/composition-api'
import { useSelect } from '@/utils-future/hooks.js'

export default {
  props: {
    value: null,
    change: {
      type: Function,
      default: noop,
    },
    placeholder: {
      type: String,
      default: '请选择门店',
    },
    multiple: {
      type: Boolean,
    },
    defaultIndex: Number,
    grantedOnly: {
      type: Boolean,
      default: false
    }
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  setup(props, context) {
    const { state } = useSelect(context, { 
      defaultIndex: props.defaultIndex,
      fetchApi: () => API.studioStudioList({grantedOnly: props.grantedOnly})
    })

    return {
      ...toRefs(state),
    }
  },
}
</script>

<style lang='scss' scoped>
</style>