<template>
  <el-select
    class="item"
    :value="value"
    filterable
    size="small"
    clearable
    :multiple="multiple"
    :placeholder="placeholder"
    @change="(v) => $emit('change', v)"
    @hook:mounted="cancalReadOnly"
    @visible-change="cancalReadOnly"
    remote
    :remote-method="handleSearch"
  >
    <el-option
      v-for="item in data"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    >
    </el-option>
  </el-select>
</template>

<script>
import { API } from '@/services/brand.js'
import { noop } from '../../utils-future/functional.js'
import { isNumber, stubTrue } from 'lodash'
import { useSelect } from '@/utils-future/hooks.js'
import { ref, toRefs, watch } from '@vue/composition-api'

export default {
  props: {
    value: null,
    change: {
      type: Function,
      default: noop,
    },
    placeholder: {
      type: String,
      default: '请选择代金券',
    },
    multiple: {
      type: Boolean,
    },
    memberId: {
      type: Number,
      default: null,
    },
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  setup(props, context) {
    const { state, refresh, handleSearch } = useSelect(context, {
      fetchApi: (query) =>
        API.couponCouponIndex({
          active: true,
          query,
          memberId: props.memberId,
          pageSize: 200,
        }),
    })

    return {
      ...toRefs(state),
      handleSearch,
    }
  },

  methods: {
    cancalReadOnly(onOff) {
      this.$nextTick(() => {
        if (!onOff) {
          const { select } = this.$refs
          const input = select.$el.querySelector('.el-input__inner')
          input.removeAttribute('readonly')
        }
      })
    },
  },
}
</script>

<style lang='scss' scoped>
</style>
