import Layout from 'views/layout/Layout'

export default {
  path: '/partner',
  redirect: '/partner/consume',
  component: Layout,
  name: 'partner',
  title: '合作商户',
  permission: ['partner'],
  icon: 'finance',
  hidden: false,
  children: [
    {
      path: 'consumptions',
      component: () => import('views/partner/consumption_record/List'),
      name: 'partnerConsumptionList',
      title: '消费记录',
      permission: ['partner', 'consumption_list'],
      keepAlive: true,
      hidden: false,
    },
    {
      path: 'consume',
      component: () => import('views/partner/consumption_record/Consume'),
      title: '储值消费',
      permission: ['partner', 'consume'],
      name: 'PartnerConsume',
      hidden: false,
    },
    {
      path: 'partners',
      component: () => import('views/partner/PartnerManage'),
      name: 'partnerManage',
      title: '商户管理',
      permission: ['partner', 'partner_manage'],
      keepAlive: true,
      hidden: false,
    },
    {
      path: 'products',
      component: () => import('views/partner/product/List'),
      name: 'partnerProductsList',
      title: '商品管理',
      permission: ['partner', 'product_list'],
      keepAlive: true,
      hidden: false,
    },
  ],
}
  