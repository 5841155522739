<template>
  <el-select :value='value' @change="v => $emit('change', v)" class="item" remote filterable :placeholder="placeholder"
              :multiple="multiple" size="small" clearable>
    <el-option-group
      v-for="category in data"
      :key="category.name"
      :label="category.name"
    >
      <el-option
        v-for="level in category.levels"
        :key="level.id"
        :label="level.name"
        :value="level.id">
      </el-option>
    </el-option-group>

  </el-select>
</template>

<script>
import { API } from '@/services/brand.js'
import { noop } from '../../utils-future/functional.js'
import { uniqBy, isEqual, clone } from 'lodash'
import { useSelect } from '@/utils-future/hooks.js'
import { ref, toRefs, watch } from '@vue/composition-api'

export default {
  props: {
    value: null,
    change: {
      type: Function,
      default: noop,
    },
    placeholder: {
      type: String,
      default: '请选择员工等级',
    },
    multiple: {
      type: Boolean,
    },
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  setup(props, context) {
    const { state, refresh } = useSelect(context, {
      defaultIndex: props.defaultIndex,
      fetchApi: async function(){
        let res = await API.coachLevelList()
        let categories = uniqBy(res.map(level => clone(level.category)), (category) => category.id)
        categories.forEach((category) => {
          category.levels = []
          res.forEach((level) => {
            if (category.id == level.category.id) {
              category.levels.push(level)
            }
          })
        })
        
        return categories
      },
    })

    return {
      ...toRefs(state),
    }
  },
}
</script>

<style lang='scss' scoped>
</style>