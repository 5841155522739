import { login, fetchProfile } from '@/api/login'
import { API } from '@/services/brand'
import { PUBLIC_API } from '@/services/public'
import { removeStorage, setStorage } from '@/utils-future/storage'
import { getToken, setToken, removeToken } from '@/utils/auth'
import permission from './route'

function buildPermissionTree(permissions) {
  let permissionTree = {}
  permissions.forEach(permission => {
    let names = permission.fullName.split("/")

    let lv1, lv2
    if (names.length === 3) {
      [lv1, _, lv2] = names
    } else if (names.length === 2) {
      [lv1, lv2] = names
    } else {
      [lv1] = names
    }

    if (!permissionTree.hasOwnProperty(lv1)) {
      permissionTree[lv1] = []
    }

    if (lv2 && !permissionTree[lv1].includes(lv2)) {
      permissionTree[lv1].push(lv2)
    }
  })
  return permissionTree
}

const user = {
  state: {
    token: getToken(),
    name: '',
    avatar: '',
    permissions: {},
    manageAllStudios: null,
    brandQrCode: null,
    currentBrand: null,
    role: null,
    brand: [],
    saasType: null,
    brandName: '',
    brandIdentifier: '',
    brandExpiryDate: '',
    hrmEnabled: false
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_BRAND_IDENTIFIER: (state, identifier) => {
      state.brandIdentifier = identifier
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_BRAND_QR_CODE: (state, brandQrCode) => {
      state.brandQrCode = brandQrCode
    },
    SET_BRAND_NAME: (state, brandName) => {
      state.brandName = brandName
    },
    SET_BRAND_EXPIRY_DATE: (state, brandExpiryDate) => {
      state.brandExpiryDate = brandExpiryDate
    },
    SET_MANAGE_ALL_STUDIOS: (state, manageAllStudios) => {
      state.manageAllStudios = manageAllStudios
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = buildPermissionTree(permissions)
    },
    SET_ROLE: (state, role) => {
      state.role = role
    },
    SET_SAAS_TYPE: (state, saasType) => {
      state.saasType = saasType
    },
    SET_HRM_ENABLED: (state, hrmEnabled) => {
      state.hrmEnabled = hrmEnabled
    },
    SET_CURRENT_BRAND: (state, brand) => {
      state.currentBrand = brand
    },
    SET_BRAND: (state, brand) => {
      state.brand = brand
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const username = userInfo.username.trim()
      return new Promise((resolve, reject) => {
        PUBLIC_API.authPassword({ login: username, password: userInfo.password })
          .then(resp => {
            // setToken(resp.token)
            setStorage('token', resp.token)
            commit('SET_TOKEN', resp.token)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchBrands({ commit }) {
      return PUBLIC_API.brandGeneralGrantedBrands().then(res => {
        const formated = res.map((v) => ({
          name: v.name,
          identifier: v.identifier,
        }))

        commit('SET_BRAND', formated)
      })
    },

    // 获取用户信息
    fetchProfile({ commit, state }) {
      return new Promise((resolve, reject) => {
        API.generalCurrentAdmin().then(res => {
          setStorage('brand', res.brand)
          commit('SET_NAME', res.name)
          commit('SET_CURRENT_BRAND', res.brand)
          commit('SET_BRAND_QR_CODE', res.brand.qrCode?.url)
          commit('SET_BRAND_NAME', res.brand?.name)
          commit('SET_BRAND_IDENTIFIER', res.brand?.identifier)
          commit('SET_BRAND_EXPIRY_DATE', res.brand?.expiryDate)
          commit('SET_SAAS_TYPE', res.brand.saasType)
          commit('SET_HRM_ENABLED', res.brand?.hrmEnabled)
          // commit('SET_AVATAR', res.)
          // commit('SET_MANAGE_ALL_STUDIOS', )
          commit('SET_ROLE', res.role)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },

    logOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', null)
        removeStorage('token')
        removeStorage('identifier')
        resolve()
      })
    },
  },
}

export default user
