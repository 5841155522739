<template>
  <sp-dialog title="小程序码" name="qrCodeManager" @open="showQrCode" @close="handleClose" :showFooter="false">
    <div class='controls-row-fluid'>
      <el-popconfirm v-if="url" title="确定要作废？" @confirm='destroyQrCode'>
        <el-button slot="reference" size="small" type="danger">
          <i class="icon icon-delete"></i>作废小程序码
        </el-button>
      </el-popconfirm>
      
      <el-button v-else size="small" type="primary" @click="createQrCode">
        <i class="icon icon-add"></i>生成小程序码
      </el-button>

      <el-button class="show-path-btn" size="small" type="primary" @click="displayPath">
        <i class="icon icon-menu"></i>展示路径
      </el-button>
    </div>
    <img class="qr-code-image" v-if="url" :src='url' />

    <span class="path" v-if="showPath">{{path}}</span>
  </sp-dialog>
</template>

<script>
import { API } from '@/services/brand'
import SpDialog from '@/components/SpDialog.vue'
import { getStorage } from '@/utils-future/storage'

export default {
  components: { SpDialog },

  props: {
  },

  data() {
    return {
      qrCode: {},
      showPath: false
    }
  },

  computed: {
    sceneParams() {
      return this.$store.getters.dialogPayload['qrCodeManager']?.[0]
    },
    url() {
      return this.qrCode?.image?.url
    },
    path() {
      const params = new URLSearchParams({...this.sceneParams, b: getStorage('identifier')}).toString()
      return `pages/auth/index?${params}`
    }
  },

  methods: {
    async showQrCode() {
      this.qrCode = await API.qrCodeShow({sceneParams: JSON.stringify(this.sceneParams)})
    },
    async createQrCode() {
      this.qrCode = await API.qrCodeCreate({sceneParams: this.sceneParams})
    },
    async destroyQrCode() {
      API.qrCodeDestroy(this.qrCode.scene)
      this.qrCode = {}
    },
    handleClose() {
      this.qrCode = {}
      this.showPath = false
    },
    displayPath() {
      this.showPath = true
    }
  },
}
</script>

<style lang='scss' scoped>
  .qr-code-image {
    width: 250px;
    height: 250px;
    margin: 0 auto;
  }
  .show-path-btn {
    margin-left: 1em;
  }
</style>