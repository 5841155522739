<template>
  <div :class="cls"  @click='handleClick'>
    <div class="checkbox"></div>
    {{label}}
  </div>
</template>

<script>
export default {
  name: 'MfSingleCheckbox',

  props: {
    value: Boolean,
    label: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  data() {
    return {
      checked: this.value,
    }
  },

  computed: {
    cls() {
      return `mf-single-checkbox ${this.checked ? 'active' : ''} ${this.disabled ? 'disabled' : ''}`
    },
  },

  watch: {
    value() {
      this.checked = this.value
    },
  },

  methods: {
    handleClick() {
      this.checked = !this.checked
      this.$emit('change', this.checked)
    },
  },
}
</script>

<style lang='scss' scoped>
.mf-single-checkbox {
  display: flex;
  align-items: center;
  color: rgba(#000, 0.65);
  font-size: 14px;
  cursor: pointer;
}

.checkbox {
  width: 14px;
  height: 14px;
  position: relative;
  border-radius: 2px;
  margin-right: 8px;
  color: #ffffff;
  border: 1px solid #dcdfe6;
  cursor: pointer;
}

.active {
  .checkbox {
    background-color: $primary;
    border: 0;

    &:before {
      content: '\e613';
      @include icon;
      @include center;
      color: inherit;
      font-size: 12px;
      transform: translate(-50%, -50%) scale(0.6);
    }
  }
}

.disabled .checkbox {
  pointer-events: none;
  background-color: #e4eaf3 !important;

  &.active {
    border: 1px solid #dcdfe6;
    color: #c0c4cc;
  }
}
</style>