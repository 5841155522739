import { addAllOption } from '@/utils/'

const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  cachedViews: state => state.app.cachedViews,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  brandQrCode: state => state.user.brandQrCode,
  name: state => state.user.name,
  permissions: state => state.user.permissions,
  permittedRoutes: state => state.route.permittedRoutes,
  cachedViews: state => state.route.cachedViews,
  routes: state => state.route.routes,
  orderChartButton: state => state.member.orderChartButton,
  studios: state => state.global.studios,
  tags: state => addAllOption(state.global.tags, 'array'),
  dialogVisible: state => name => state.global.activeDialog.includes(name),
  dialogPayload: state => state.global.dialogPayload,
  saasType: state => state.user.saasType,
  currentBrand: state => state.user.currentBrand,
  brandName: state => state.user.brandName,
  brandIdentifier: state => state.user.brandIdentifier,
  brandExpiryDate: state => state.user.brandExpiryDate,
  hrmEnabled: state => state.user.hrmEnabled,
}

export default getters
