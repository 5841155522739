export default {
  
  model: {
    prop: 'value',
    event: 'change',
  },
  
  props: {
    value: null,
  },
  
  data() {
    return {
      current: this.value,
    }
  },
  
  watch: {
    value(val) {
      if (val !== this.current) {
        this.current = val
      }
    },
    
    current(val) {
      this.$emit('change', val)
    },
  },
  
  methods: {
  },
}
