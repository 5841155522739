import Layout from 'views/layout/Layout'
import { get } from "lodash";

export default {
  path: '/products',
  redirect: '/products/list',
  component: Layout,
  name: 'products',
  title: '训练产品',
  hidden: false,
  icon: 'grouptraining',
  permission: ['course_product'],
  children: [
    {
      path: 'list',
      component: () => import('views/kid/products/Index'),
      // component: () => import('views/ComingSoon'),
      name: 'productsList',
      title: '训练产品',
      permission: ['course_product', 'list'],
      hidden: false,
    },
    {
      path: 'workout',
      component: () => import('views/kid/ktas/workout/Index'),
      // component: () => import('views/ComingSoon'),
      name: 'ktasWorkoutsList',
      title: '课程教案',
      permission: ['course_product', 'ktas_workout'],
      hidden: false,
    },
    {
      path: 'workouts/:workoutId/outlines',
      component: () => import('views/kid/ktas/outline/Index'),
      title: '课程教案',
      keepAlive: true,
      name: 'KtasOutlineIndex',
      children: [
        {
          path: ':outlineId/sections',
          component: () => import('views/kid/ktas/section/Index'),
          title: '课程小节',
          keepAlive: true,
          name: 'KtasSectionIndex',
          root: true
        },
      ]
    },
    // {
    //   path: 'games',
    //   component: () => import('views/kid/ktas/game/Index'),
    //   title: '游戏库',
    //   keepAlive: true,
    //   name: 'KtasGameIndex',
    //   permission: ['course_product', 'ktas_game'],
    //   hidden: false,
    // },
    {
      path: 'training_methods',
      component: () => import('views/kid/ktas/training_method/Index'),
      title: '训练方式',
      keepAlive: true,
      name: 'KtasTrainingMethodIndex',
      permission: ['course_product', 'ktas_training_method'],
      hidden: false,
    },

  ]
}
