let result = []

// 找到当前目录下所有的vue文件
const requireComponent = require.context('.', false, /\.vue$/)

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  result.push(componentConfig.default || componentConfig)
})

export default result
