<template>
  <div class="mf-masonry">
    <div class="masonry-wrapper" :style='wrapperStyle'>
      <div 
        v-for="(column, i) in showData"
        :key="'columns' + i"
        class="masonry-column"
        :style='boxStyle'
      >
        <div 
          class="item"
          v-for="(item, j) in column"
          :key='"rows" + j'
        >
          <slot v-bind:item='item'></slot>
        </div>
      </div>
    </div>
    
    <div class="masonry-footer" v-if="total">
      <div class='item-total'>共 {{total}} 条</div>
      <el-pagination 
        background 
        layout="prev, pager, next" 
        :total="total" 
        :page-size="pageSize" 
        :current-page="page" 
        @current-change='changePage'
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MfMasonry',
  
  props: {
    data: Array,
    rows: {
      type: Number,
      default: 3,
    },
    columns: {
      type: Number,
      default: 2,
    },
    gutters: {
      type: Number,
      default: 17,
    },
    pageSize: {
      type: Number,
      default: 6,
    },
    total: Number,
    page: Number,
  },

  data() {
    return {
    }
  },

  computed: {
    boxStyle() {
      let width = `calc(${(1 / this.rows) * 100}% - ${this.gutters}px)`
      let margin = `0 ${this.gutters / 2}px`
      return {
        width,
        margin,
      }
    },

    wrapperStyle() {
      let margin = `0 ${-this.gutters / 2}px`
      return {
        margin,
      }
    },

    showData() {
      let { data, rows } = this
      if (!data) {
        return
      }
      let result = []
      for (let i = 0; i < rows; i++) {
        let arr = data.filter((item, j) => j % rows === i)
        result.push(arr)
      }
      return result
    },
  },

  methods: {
    changePage(value) {
      this.$emit('changePage', value)
    },
  },
}
</script>

<style lang='scss' scoped>
.masonry-wrapper {
}

.masonry-column {
  display: inline-block;
  vertical-align: top;
}

.item {
  margin-bottom: 16px !important;
}

.name {
  font-size: 18px;
}

.masonry-footer {
  color: #5f6265;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
}
</style>