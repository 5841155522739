import Layout from 'views/layout/Layout';

export default {
  path: '/members',
  redirect: '/members/list',
  name: 'members',
  component: Layout,
  title: '会员管理',
  permission: ['member'],
  hidden: false,
  icon: 'member',
  children: [
    {
      path: 'list',
      component: () => import('views/kid/member/List'),
      title: '会员列表',
      keepAlive: true,
      name: 'MemberList',
      permission: ['member', 'list'],
      hidden: false
    },
    {
      path: 'operating',
      title: '团课运营',
      redirect: '/members/operating/new_members',
      component: () => import('views/kid/member_operating/Index'),
      name: 'NewMemberIndex',
      permission: ['member', 'group_operating'],
      hidden: false,
      children: [
        {
          path: 'new_members',
          name: 'NewMembers',
          component: () => import('views/kid/member_operating/Index'),
          title: '新会员运营',
          permission: ['member', 'group_operating'],
        },
        {
          path: 'new_member_bookings',
          name: 'NewMemberBookings',
          component: () => import('views/kid/member_operating/Index'),
          title: '新会员到店',
          permission: ['member', 'group_operating'],
        },
        {
          path: 'active_members',
          name: 'ActiveMembers',
          component: () => import('views/kid/member_operating/Index'),
          title: '活跃会员',
          permission: ['member', 'group_operating'],
        },
      ]
    },
    {
      path: ':memberId',
      redirect: '/members/:memberId/membership',
      component: () => import('views/kid/member/Detail'),
      name: 'memberDetail',
      title: '会员详情',
      children: [
        {
          path: 'membership',
          component: () => import('views/kid/member/detail/Membership'),
          name: 'memberDetailMembership',
          title: '余额统计',
          children: [
            {
              path: 'records',
              component: () => import('views/kid/member/record/Membership'),
              name: 'memberRecordMembership',
              title: '账户使用记录',
              root: true,
            },
          ],
        },
        {
          path: 'kid',
          component: () => import('views/kid/member/detail/Kid'),
          name: 'memberDetailKid',
          title: '儿童管理'
        },
        {
          path: 'exam',
          component: () => import('views/kid/member/detail/Exam'),
          name: 'memberDetailExam',
          title: '体测记录'
        },
        {
          path: 'training',
          component: () => import('views/kid/member/detail/Training'),
          name: 'memberDetailTraining',
          title: '训练统计'
        },
        {
          path: 'order',
          component: () => import('views/kid/member/detail/Order'),
          name: 'memberDetailOrder',
          title: '消费统计'
        },
        {
          path: 'account_member/list',
          component: () => import('views/kid/member/components/AccountMembers'),
          name: 'membershipAccountMemberList',
          title: '共享卡管理',
          root: true,
        },
      ],
    }
  ],
};
