<template>
  <el-select 
    v-model="gender" 
    placeholder="性别"
    @change='change'
    class="select-gender item"
  >
    <el-option
      v-for="item in options"
      :key="item.name"
      :label="item.name"
      :value="item.value"
    >
    </el-option>
  </el-select>
</template>

<script>
import { GENDER_OPTION_WITHOUT_ALL, GENDER_OPTION } from '@/constant/options'

export default {
  name: 'MfGender',
  
  props: {
    value: String,
    withAllOption: {
      type: Boolean,
      default: false,
    },
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  watch: {
    value() {
      this.gender = this.value
    }
  },

  data() {
    return {
      gender: this.value,
      options: this.withAllOption ? GENDER_OPTION : GENDER_OPTION_WITHOUT_ALL,
    }
  },

  methods: {
    change(value) {
      this.$emit('change', value)
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
