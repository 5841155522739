<template>
  <div class="notification-inbox">
    <el-badge :value="inbox.unreadCount || null" :max="99" class="item">
      <a @click="$openDialog('notificationList')" class="notification"><i class="el-icon-bell"></i></a>
    </el-badge>

    <sp-dialog title="通知中心" name="notificationList" width='1000px' :showFooter="false">
      <sp-table
        ref='table'
        :operation-id="operationId"
        :config="config"
        :hasFilters="false"
        showIndex
        withoutCard
      >
        <template #extra="row">
          <div
            class="item"
            @click="showContent(row)"
          >
            查看
          </div>
        </template>
      </sp-table>
    </sp-dialog>

    <sp-dialog 
      v-if="current" 
      class="notification-content"
      :title="current.title" 
      name="notificationContent" 
      width='800px' 
      :showFooter="false"
      @close="closeContent"
    >
      <div v-html="current.content"></div>
      <div class="contact-qr">
        <img :src="contact_qr"/>
      </div>
      <el-button class="close-btn" type='primary' @click="$closeDialog('notificationContent')">已知晓</el-button>
    </sp-dialog>
  </div>
</template>

<script>
import { OPERATION_ID } from '@/constants/v2/operationId'
import { API } from '@/services/v2/brand'
import SpDialog from '@/components/SpDialog.vue'
import SpTable from '@/components/SpTable.vue'
import contact_qr from 'assets/images/contact_qr.jpg'

export default {
  components: { SpDialog, SpTable },

  data() {
    return {
      contact_qr,
      operationId: OPERATION_ID.v2BrandNotificationIndex,
      config: {
        apiVersion: 'v2',
        order: [
          "title",
          "createdAt",
          "read"
        ],
        customColumns: {},
      },
      inbox: {
        unreadCount: 0,
        readList: []
      },
      current: null,
    }
  },

  computed: {
    unread() {
      return this.inbox.unreadCount > 0
    }
  },

  mounted() {
    this.refreshInbox()
  },

  methods: {
    refreshInbox() {
      API.v2BrandNotificationInbox().then((data) => {
        this.inbox = data
        if (data.readList && data.readList.length > 0) {
          this.current = data.readList[0]
          this.$openDialog('notificationContent')
        }
      })
    },

    showContent(data) {
      this.current = data
      this.$openDialog('notificationContent')
    },

    closeContent() {
      if (!this.current.read) {
        API.v2BrandNotificationRead(this.current.id)
          .then(() => this.refreshInbox())
        this.current.read = true
      }
      
      this.current = null
    }
  },
}
</script>

<style lang='scss'>
  .notification-inbox {
    display: inline-block;
    line-height: 1;
    .item {
      margin-left: 30px;
    }
  }
  .notification-content {
    line-height: 1.5;
    a {
      cursor: pointer;
      color: #409eff;
    }
    p {
      margin: 0;
    }
    ol, ul {
      margin-left: 1.5em;
    }
    ol {
      list-style-type: decimal;
    }
    ul {
      list-style-type: circle;
    }
    .contact-qr {
      margin: 45px 0 15px;
      img {
        width: 100%;
      }
    }
    .close-btn {
      margin: 25px auto 0;
      width: 6em;
      display: block;
    }
  }
</style>
