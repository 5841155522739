<template>
  <el-select :size='size' v-model="type" placeholder="商品类型" class='item' @change='change'>
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.name"
      :value="item.value">
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: 'MfCardType',
  
  props: {
    value: null,
    size: {
      type: String,
      default: 'medium',
    },
    all: {
      type: Boolean,
      default: true,
    }
  },
  
  data() {
    return {
      type: this.value,
    }
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  computed: {
    options() {
     
    },
  },

  methods: {
    change(value) {
      this.$emit('change', value)
    },
  },
}

let PRODUCT_TYPE = [
  {
    name: '团课',
    value: 'group_training',
  },
  {
    name: 'Pride',
    value: 'prime',
  },
  {
    name: '1V2迷你营',
    value: 'mini_camp',
  },
]
</script>

<style lang="scss" scoped>
</style>
