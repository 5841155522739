<template>
  <div class="menu-wrapper">
    <router-link to="/home">
      <!-- <el-menu-item index='backToHome' class="menu-item-home">
        <i class='icon icon-home icon-w'></i>
        <span>首页</span>
      </el-menu-item> -->
    </router-link>
    <template v-for="item in routes" v-if="!item.hidden&&item.children">
      <router-link 
        v-if="hasOneShowingChildren(item) && !item.children[0].children&&!item.alwaysShow" 
        :to="item.path+'/'+item.children[0].path"
        :key="item.children[0].name"
      >
        <el-menu-item :index="item.path+'/'+item.children[0].path" :class="{'submenu-title-noDropdown':!isNest}">
          <i :class='iconCls(item.meta.icon)' v-if="item.meta&&item.meta.icon"></i>
          <span v-if="item.children[0].meta&&item.children[0].meta.title" slot="title">{{item.meta.title}}</span>
        </el-menu-item>
      </router-link>

      <el-submenu :index="item.name||item.path" :key="item.name" v-else>
        <template slot="title">
          <i :class='iconCls(item.meta.icon)' v-if="item.meta&&item.meta.icon"></i>
          <span v-if="item.meta&&item.meta.title" slot="title">{{item.meta.title}}</span>
        </template>

        <template v-for="child in item.children" v-if="!child.hidden">
          <!-- <sidebar-item :is-nest="true" class="nest-menu" v-if="child.children&&child.children.length>0" :routes="[child]" :key="child.path"></sidebar-item> -->

          <router-link :to="item.path+'/'+child.path" :key="child.name">
            <el-menu-item :index="item.path+'/'+child.path">
              <i :class='iconCls(child.meta.icon)' v-if="child.meta&&child.meta.icon"></i>
              <span v-if="child.meta&&child.meta.title" slot="title">{{child.meta.title}}</span>
            </el-menu-item>
          </router-link>
        </template>
      </el-submenu>
    </template>

    <!-- <a id="jumpt-to-doc" :href="docUrl" target='_blank' v-if="isDevelopment">
      <el-menu-item index="jumpt-to-doc" class="submenu-title-noDropdown">
        <i class='icon icon-document icon-w'></i>
        <span slot="title">查看文档</span>
      </el-menu-item>
    </a> -->
  </div>
</template>

<script>

export default {
  name: 'SidebarItem',
  props: {
    routes: {
      type: Array
    },
    isNest: {
      type: Boolean,
      default: false
    }
  },
  
  computed: {
    legacyAdminUrl() {
      let legacyDomain = process.env.LEGACY_DOMAIN
      return `${legacyDomain}/back_office/home`
    },

    docUrl() {
      return `http://xtatnt.gitee.io/mfitness-admin-doc/#/`
    },

    isDevelopment() {
      return process.env.NODE_ENV !== 'production'
    }
  },
  
  methods: {
    hasOneShowingChildren(item) {
      let children = item.children
      const showingChildren = children.filter(item => {
        return !item.hidden
      })
      if (showingChildren.length === 1 && item.meta.title === showingChildren[0].meta.title) {
        return true
      }
      return false
    },

    iconCls(icon) {
      return {
        'icon-w': true,
        'icon': true,
        [`icon-${icon}`]: true,
      }
    },
  }
}
</script>

<style lang='scss' scoped>
.icon-logout {
  transform: rotate(-180deg);
}
</style>