<template>
  <el-popconfirm :title="`确定要${state ? '暂停' : '激活'} 吗？`" @confirm='handleConfirm'>
    <div :class='{"sp-toggle-state": true, "danger": state}' slot='reference'>
      {{state ? '暂停' : '激活'}}
    </div>
  </el-popconfirm>
</template>

<script>
import { eventEmitter } from './eventEmitter'
export default {
  name: 'SpToggleState',
  props: {
    state: {
      type: Boolean,
    },
    request: {
      type: Function,
    },
    operationId: {
      type: String,
    },
  },

  data() {
    return {}
  },

  methods: {
    handleConfirm() {
      if (this.request) {
        this.request({ active: !this.state })

        if (this.operationId) {
          eventEmitter.emit(this.operationId)
        }
      }
    },
  },
}
</script>

<style lang='scss' scoped>
.sp-toggle-state {
  &.danger {
    color: $red;
  }
}
</style>