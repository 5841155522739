import Tags from "./Tags.vue";
import ButtonGroup from "./ButtonGroup.vue";
import Gender from "./Gender.vue";
import Search from "./Search.vue";
import Studios from "./Studios.vue";
import ProductType from "./ProductType.vue";
import ProductProvider from "./ProductProvider.vue";
import CoachLevel from "./CoachLevel.vue";
import PaymentMethod from "./PaymentMethod.vue";
import Membership from "./Membership.vue";
import DatePicker from "./DatePicker.vue";
import Coach from "./Coach.vue";
import Member from "./Member.vue";
import User from "./User.vue";
import StudioPicker from "./StudioPicker.vue";
import Workout from "./Workout.vue";
import Product from "./Product.vue";
import SpSelect from "./SpSelect.vue";

export {
  Tags,
  ButtonGroup,
  Gender,
  Search,
  Studios,
  ProductType,
  ProductProvider,
  CoachLevel,
  PaymentMethod,
  Membership,
  DatePicker,
  Coach,
  Member,
  User,
  StudioPicker,
  Workout,
  Product,
  SpSelect
}
