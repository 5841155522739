<template>
  <div class='mf-editor-wrapper'>
    <quill-editor v-loading='loading' v-model="content" ref="quillEditor" :options="options"></quill-editor>
    <input type="file" @change='uploadImage' ref='hiddenInput' class='hidden-input'>
  </div>
</template>

<script>
import { DirectUpload } from '@rails/activestorage'
import Quill from 'quill'
import { uniqueId } from 'lodash'
import { API } from '@/services/brand'

let Size = Quill.import('attributors/style/size')
Size.whitelist = [
  '14px', '18px', '22px', '28px', '36px', '48px',
  '0.8rem', '1rem', '1.5rem', '2rem', '2.5rem', '3rem', '4rem', '5rem'
]
Quill.register(Size, true)

const directUpload = (upload) =>
  new Promise((resolve, reject) => {
    upload.create((error, res) => {
      if (error) {
        reject(error)
      } else {
        resolve(res)
      }
    })
  })
const uploadFile = async (file) => {
  const upload = new DirectUpload(file, process.env.DIRECT_UPLOAD_URL)
  const res = await directUpload(upload)
  const result = await API.attachmentShow(res.signed_id)

  return result
}

export default {
  name: 'MfEditor',

  props: {
    value: null,
    textOnly: {
      type: Boolean,
      default: false
    },
    fontSize: {
      default: null,
    },
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  data() {
    var toolbarOptions = this.fontSize ? [
      [{ size: this.fontSize }],
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      [{ list: 'ordered' }, { list: 'bullet' }],
    ] :
    [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      [{ list: 'ordered' }, { list: 'bullet' }],
    ]

    if (!this.textOnly) {
      toolbarOptions = [...toolbarOptions, ['image']]
    }

    return {
      content: this.value,
      inputId: uniqueId('upload'),
      options: {
        modules: {
          toolbar: toolbarOptions
        }
      },
      loading: false,
    }
  },

  watch: {
    content(val) {
      this.change(val)
    },

    value(val) {
      if (val !== this.content) {
        this.content = val
      }
    },
  },

  mounted() {
    let vm = this
    let input = this.$refs.hiddenInput
    setTimeout(() => {
      this.$refs.quillEditor.quill
        .getModule('toolbar')
        .addHandler('image', imgHandler)
    }, 1000)

    function imgHandler(image) {
      vm.addImgRange = vm.$refs.quillEditor.quill.getSelection()
      if (image) {
        let el = input
        el.click()
      }
    }
  },

  methods: {
    async uploadImage(e) {
      let filter = /image\/jpeg|image\/jpg|image\/png|image\/gif/i
      // let reader = new FileReader()
      // let file = event.target.files[0]
      const file = e.target.files[0]
      this.loading = true
      const url = process.env.DIRECT_UPLOAD_URL

      if (!filter.test(file.type)) {
        this.$message({
          message: '文件类型必须为jpeg、jpg、png、gif中的一种！',
          type: 'error',
        })

        return
      } else {
        const { url } = await uploadFile(file)

        this.insertImg(url)
      }
    },

    insertImg(url) {
      this.addRange = this.$refs.quillEditor.quill.getSelection()
      console.log('url: ', url.log)
      this.$refs.quillEditor.quill.insertEmbed(
        this.addRange !== null ? this.addRange.index : 0,
        'image',
        url,
        Quill.sources.USER
      )
      this.loading = false
    },

    change(val) {
      this.$emit('change', val)
    },
  },
}
</script>

<style lang='scss' scoped>
.mf-editor-wrapper {
  width: 400px;
}

.hidden-input {
  position: absolute;
  visibility: hidden;
}
/deep/.ql-snow {
  .ql-picker {
    &.ql-size {
      .ql-picker-label,
      .ql-picker-item {
        &::before {
          content: attr(data-value) !important;
        }
      }

      .ql-picker-label::before {
        position: absolute;
      }
    }
  }
}
</style>
