<template>
  <el-select class="item" remote :value="value" filterable :allow-create="allowCreate" default-first-option size="small" clearable
              :multiple="multiple" :placeholder="placeholder" @change="(v) => $emit('change', v)">
    <el-option v-for="item in data" :key="item.tag" :label="label(item)" :value="item.tag">
    </el-option>
  </el-select>
</template>

<script>
import { API } from '@/services/brand.js'
import { noop } from '../../utils-future/functional.js'
import { useSelect } from '@/utils-future/hooks.js'
import { ref, toRefs, watch } from '@vue/composition-api'

export default {
  name: 'SpMemberTagSelect',
  props: {
    value: null,
    change: {
      type: Function,
      default: noop,
    },
    displayTagCount: {
      type: Boolean,
      default: false,
    },
    allowCreate: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '请输入标签',
    },
    multiple: {
      type: Boolean,
      default: false
    },
    memberType: {
      type: String,
      default: 'all'
    }
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  setup(props, context) {
    const { state, refresh } = useSelect(context, {
      fetchApi: async () => API.memberMemberTags({memberType: props.memberType, displayTagCount: props.displayTagCount}),
    })

    return {
      ...toRefs(state),
      label: (item) => {
        if (props.displayTagCount) {
          return `${item.tag} (${item.count})`
        } else {
          return item.tag
        }
      }
    }
  },
}
</script>

<style lang='scss' scoped>
</style>
