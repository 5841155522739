import { asyncRouterMap, kidAsyncRouterMap, constantRouterMap } from '@/router'
import Partner from 'router/routers/partners';
import routerHelper from 'utils/router';

//TODO: improve code quality

/**
 * 通过meta.permission匹配用户权限
 * @param route
 * @param permission
 */
function hasPermission(route, permissions) {
  if (route.meta && route.meta.permission) {
    if (route.meta.permission.length == 2) {
      return permissions.hasOwnProperty(route.meta.permission[0]) && permissions[route.meta.permission[0]].includes(route.meta.permission[1])
    } else {
      return permissions.hasOwnProperty(route.meta.permission[0]) 
    }
  } else {
    return true
  }
}

/**
 * 递归过滤异步路由表，返回符合用户角色权限的路由表
 * @param asyncRouterMap
 * @param permissions
 */
function permitRoutes(asyncRouterMap, permissions) {
  const accessedRouters = asyncRouterMap.filter(route => {
    if (hasPermission(route, permissions)) {
      if (route.children && route.children.length) {
        route.children = permitRoutes(route.children, permissions)
      }
      return true
    }
    return false
  })
  return accessedRouters
}

function filterCachedViews(routes) {
  let cachedViews = []
  routes.forEach(route => {
    if (route.meta && route.meta.keepAlive) {
      cachedViews.push(route.name)
    }
  
    if (route.children && route.children.length) {
      cachedViews = cachedViews.concat(filterCachedViews(route.children))
    }
  })

  return cachedViews
}

const route = {
  state: {
    routes: constantRouterMap,
    cachedViews: [],
    permittedRoutes: []
  },
  mutations: {
    SET_ROUTERS: (state, routes) => {
      state.permittedRoutes = routes
      state.routes = constantRouterMap.concat(routes)
      state.cachedViews = filterCachedViews(state.routes)
    }
  },
  actions: {
    GenerateRoutes({ commit, getters }, data) {
      return new Promise(resolve => {
        const { permissions } = data
        let permittedRoutes 
        if (getters.saasType === 'zishi_power_kid') {
          permittedRoutes = permitRoutes(kidAsyncRouterMap, permissions)
        } else {
          console.log(getters.brandIdentifier)

          permittedRoutes = permitRoutes(asyncRouterMap, permissions)
        }
        commit('SET_ROUTERS', permittedRoutes)
        resolve()
      })
    }
  }
}

export default route
