import Layout from 'views/layout/Layout'
import RouterView from 'views/layout/RouterView'


export default {
  path: '/membership',
  redirect: '/membership/order',
  name: 'membership',
  component: Layout,
  title: '订单管理',
  hidden: false,
  icon: 'card',
  permission: ['order'],
  children: [

    {
      path: 'order',
      component: () => import('views/kid/membership/order/Detail'),
      name: 'membershipOrderDetail',
      title: '订单列表',
      hidden: false,
      permission: ['order', 'list'],
    },
    // {
    //   path: 'prepay_card',
    //   component: () => import('views/kid/membership/prepaycard/List'),
    //   name: 'prepayCard',
    //   title: '储值卡管理',
    //   hidden: false,
    //   permission: ['order', 'prepay_card'],
    // },
    {
      path: 'membership_cards',
      component: () => import('views/kid/membership/card/List'),
      name: 'membershipCardList',
      title: '会员卡管理',
      hidden: false,
      permission: ['order', 'membership_cards'],
    },
    // {
    //   path: 'coupons',
    //   redirect: '/membership/coupons/list',
    //   name: 'coupons',
    //   component: RouterView,
    //   title: '代金券管理',
    //   hidden: false,
    //   permission: ['order', 'list'],
    //   children: [
    //     {
    //       path: 'list',
    //       component: () => import('views/kid/membership/coupon/Index'),
    //       name: 'couponList',
    //       title: '代金券列表',
    //     },
    //     {
    //       path: ':id/event_coupon',
    //       component: () => import('views/kid/membership/coupon/components/CouponEventCouponIndex'),
    //       title: '代金券营销详情',
    //       name: 'CouponEventCouponIndex',
    //     }
    //   ]
    // },
    {
      path: 'refund',
      component: () => import('views/kid/membership/order/Detail?tab=refund'),
      name: 'membershipRefundDetail',
      title: '退款列表',
      permission: ['order', 'refund'],
    },
    {
      path: 'order/add',
      component: () => import('views/kid/membership/order/OrderAdd'),
      name: 'membershipOrderOrderAdd',
      title: '创建订单',
      permission: ['order', 'list'],
    },
    // {
    //   path: 'refund/add',
    //   component: () => import('views/kid/membership/order/RefundAdd'),
    //   name: 'membershipOrderRefundAdd',
    //   title: '创建退款',
    //   // permission: ['order', 'refund'],
    // },
    {
      path: 'card/:cardId/detail',
      component: () => import('views/kid/membership/card/Detail'),
      name: 'membershipCardDetail',
      title: '会员卡详情',
      permission: ['order', 'list'],
    },
    // {
    //   path: 'new',
    //   component: () => import('views/kid/membership/card/MembershipCardNew'),
    //   name: 'membershipMembershipCardNew',
    //   title: '新建卡种',
    //   permission: ['order', 'list'],
    // },
    // {
    //   path: ':cardId/edit',
    //   component: () => import('views/kid/membership/card/MembershipCardEdit'),
    //   name: 'membershipMembershipCardEdit',
    //   title: '编辑卡种',
    //   permission: ['order', 'list'],
    // },
    // {
    //   path: 'card/:cardId/new',
    //   component: () => import('views/kid/membership/card/New'),
    //   name: 'membershipCardNew',
    //   title: '新建会员卡',
    //   permission: ['order', 'list'],
    // },
    // {
    //   path: 'card/:cardId/:optionId/edit',
    //   component: () => import('views/kid/membership/card/Edit'),
    //   name: 'membershipCardEdit',
    //   title: '编辑会员卡',
    //   permission: ['order', 'list'],
    // },
  ],
}
