<template>
  <el-select 
    v-model="coachId" 
    :placeholder="placeholder" 
    size='small'
    class='item'
    remote
    filterable
    clearable
    :remote-method="fetchFilterData"
  >
    <el-option
      v-for="(item) in options"
      :key="item.id"
      :label="item.label"
      :value="item.id">
    </el-option>
  </el-select>
</template>

<script>
import  { API }  from "@/services/brand"

export default {
  name: 'MfStudio',
  
  props: {
    value: null,
    category: null,
    levelId: Number,
    type: {
      type: String,
      default: 'normal',
    },
    placeholder: {
      type: String,
      default: '输入门店进行搜索',
    },
    all: false,
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  data() {
    return {
      coachId: this.value,
      options: null,
      isLoading: false
    }
  },
  
  computed: {
  
  },
  
  watch: {
    value(value) {
      this.coachId = value
    },

    coachId() {
      this.change()
    },

    filter: {
      handler: function() {
        this.fetchFilterData()
      },
      deep: true,
    },
  },
  
  async created() {
    await this.fetchFilterData()
  },
  
  methods: {
    async fetchFilterData(query) {
      this.isLoading = true

      let params = {
        id: this.coachId,
        query,
      }
      await API.studioStudioList(params).then(res => {
        if(this.all){
          this.options = [{
            id: null, 
            label: '全部门店',
          }, ...this.formatData(res)]
        }else{
          this.options = this.formatData(res)
        }
      })
      
      this.isLoading = false
    },

    formatData(arr) {
      return arr.map(o => ({...o, label: `${o.name}`}))
    },

    change() {
      this.$emit('change', this.coachId)
      this.$emit('getObjByChange', this.options.filter(o => o.id === this.coachId)[0])
    }
  },
}
</script>

<style lang='scss' scoped>
</style>
