<template>
  <el-scrollbar wrapClass="scrollbar-wrapper">
    <div class="custom-menu-item toggle-sidebar" @click='handleToggleSidebar'>
      <i class='icon icon-menu icon-w'></i>
    </div>
    <el-menu
      mode="vertical"
      :show-timeout="200"
      :default-active="$route.path"
      :collapse="isCollapse"
      :unique-opened='true'
      background-color="#f2f4f8"
      text-color="rgba(0, 0, 0, .45)"
      active-text-color="#409eff"
    >
      <sidebar-item :routes="permittedRoutes"></sidebar-item>
    </el-menu>
  </el-scrollbar>
</template>

<script>
import { mapGetters } from 'vuex'
import SidebarItem from './SidebarItem'

export default {
  components: { SidebarItem },

  computed: {
    ...mapGetters([
      'sidebar',
      'permittedRoutes'
    ]),
    isCollapse() {
      return !this.sidebar.opened
    },
  },
  
  methods: {
    handleToggleSidebar() {
      this.$store.dispatch('ToggleSideBar')
    },
  }
}
</script>

<style lang="scss" scoped>
.custom-menu-item {
  width: 100%;
  height: 40px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover,
  &:focus {
    background-color: #e6e8eb;
  }
}

i {
  font-size: 14px;
  cursor: pointer;
  color: rgba(#000, .85);
  width: 16px;
}

.toggle-sidebar {
  i {
    position: absolute;
    top: 50%;
    right: 18px;
    transition: all .5s;
    transform: rotate(180deg) translate(0, -50%);
    transform-origin: center 0;
  }
}
</style>
