<template>
  <el-date-picker
    v-model="duration"
    type="daterange"
    start-placeholder="开始日期"
    end-placeholder="结束日期"
    class="item mf-date-picker"
    size="small"
    :picker-options='pickerOptions'
    :unlink-panels='true'
    :style='style'
    :clearable='false'
    v-on="$listeners"
  >
  </el-date-picker>
</template>

<script>
import { generateTimeRange } from '@/utils/date'
import { isString, isNumber } from 'lodash'

export default {
  name: 'MfDatePicker',
  
  props: {
    value: Array,
    width: [String, Number],
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  data() {
    return {
      duration: this.value,
      pickerOptions: { shortcuts: [0, 1, 3, 6].map(generateTimeRange), firstDayOfWeek: 1 },
    }
  },
  
  computed: {
    actualWidth() {
      return typeof this.width === 'number' ? `${this.width}px` : this.width
    },

    style() {
      if (this.actualWidth) {
        return { width: this.actualWidth }
      } else {
        return {}
      }
    },
  },
  
  watch: {
    duration() {
      this.change()
    },

    value(val) {
      this.duration = val
    }
  },

  methods: {
    change() {
      this.$emit('change', this.duration)
    },
  },
}
</script>

<style lang='scss'>
.mf-date-picker {
  width: 230px;

  /deep/ .el-range__close-icon {
    width: 0;
  }

  /deep/ .el-range-separator {
    width: auto;
  }
}
</style>