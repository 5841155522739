var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mf-overview-card",class:{
  'single-row': !_vm.multi,
  'multi-row': _vm.multi,
}},[_c('el-row',{attrs:{"gutter":16}},_vm._l((_vm.data),function(card,i){return _c('el-col',{key:i,style:({cursor: _vm.$get(_vm.urls, ("" + ([i]))) ? 'pointer' : 'auto'}),attrs:{"span":_vm.span ? _vm.span[i] : _vm.defaultSpan},nativeOn:{"click":function($event){_vm.handleClickUrl(_vm.$get(_vm.urls, ("" + ([i]))))}}},[_c('div',{class:{
          'overview-card': true, 
          'custom-card': _vm.$get(_vm.actualColors, (([i]) + ".bg")),
        },style:({
          backgroundColor: _vm.$get(_vm.actualColors, (([i]) + ".bg"), '#fff'),
          color: _vm.$get(_vm.actualColors, (([i]) + ".color"), '#fff'),
        })},[_c('div',{staticClass:"overview-card-wrapper"},_vm._l((card),function(item,j){return _c('div',{key:j,staticClass:"item",class:{primary: !j},style:(_vm.generateStyle(j, item.url)),on:{"click":function($event){return _vm.handleClickItem(item.url)}}},[_c('div',{staticClass:"label"},[(item.label !== undefined)?_c('render',{attrs:{"render":item.label}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"value"},[(item.value !== undefined)?_c('render',{attrs:{"render":item.value}}):_vm._e()],1)])}),0),_vm._v(" "),(_vm.$get(_vm.tips, ("[" + i + "]tips")))?_c('div',{staticClass:"tips"},[_c('el-tooltip',{attrs:{"effect":"dark","content":_vm.isString(_vm.tips[i].tips) ? _vm.tips[i].tips : '',"placement":"bottom"}},[(!_vm.isString(_vm.tips[i].tips))?_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('render',{attrs:{"render":_vm.tips[i].tips}})],1):_vm._e(),_vm._v(" "),_c('i',{staticClass:"el-icon-question"})])],1):_vm._e(),_vm._v(" "),(_vm.isNumber(_vm.patch) && i === _vm.patch && _vm.patch < _vm.data.length - 1)?_c('div',{staticClass:"patch",style:({backgroundColor: _vm.$get(_vm.colors, (([i]) + ".bg"), '#fff')})}):_vm._e(),_vm._v(" "),(_vm.custom)?[(_vm.custom[i])?_c('render',{attrs:{"render":_vm.custom[i]}}):_vm._e()]:_vm._e()],2)])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }