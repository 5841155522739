<template>
  <mf-dialog
    :name='name'
    :title='title'
  >
    <div class="img-wrapper">
      <img :src="url" />
    </div>
  </mf-dialog>
</template>

<script>
export default {
  name: 'MfImageDialog',
  props: {
    url: String,
    title: String,
    name: String,
  },

  methods: {
    handleClose() {
      this.$emit('close')
    }
  }
}
</script>

<style lang='scss' scoped>
/deep/ .img-wrapper {
  width: 240px;
  height: 240px;
  margin-left: 10px;
}

/deep/ .el-dialog {
  width: 300px;

  img {
    width: 100%;
  }
}
</style>
