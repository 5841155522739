<template>
  <div class="mf-horizontal-bar">
    <div
      class="bar-wrapper"
    >
      <template
        v-for="(item, i) in showData"
      >
        <template v-if='showTooltips'>
          <el-tooltip effect="dark" :content="`${item.label}：${item.value}`" :key="'data'+i">
            <div
              :class="{bar: true, hidden: item.ratio === 0, inactive: i !== activeIndex && activeIndex !== null}"
              :style="generateStyle(item)"
              @click="handleClick(item.label, i)"
            >
              {{`${item.label}：${item.value}`}}
            </div>
          </el-tooltip>
        </template>
        <template v-else>
          <div
            :key="'data'+i"
            :class="{bar: true, hidden: item.ratio === 0, inactive: i !== activeIndex && activeIndex !== null}"
            :style="generateStyle(item)"
            @click="handleClick(item.label, i)"
          >
            {{`${item.label}：${item.value}`}}
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import fetch from '@/mixins/fetch'

export default {
  name: 'MfHorizontalBar',
  
  mixins: [fetch],
  
  props: {
    data: Array,
    fetchApi: Function,
    formatFunc: Function,
    clickable: {
      type: Boolean,
      default: false,
    },
    gutter: {
      type: [String, Number],
      default: '4px',
    },
    average: {
      type: Boolean,
      default: false,
    },
    showTooltips: {
      type: Boolean,
      default: true,
    }
  },
  
  data() {
    return {
      sourceData: null,
      activeIndex: null,
    }
  },
  
  computed: {
    showData() {
      if (!this.sourceData) {
        return
      }
      let result
      if (!this.average) {
        let sum = this.sourceData.reduce((sum, arr) => sum += arr.value, 0)
        result = this.sourceData.map(item => ({
          ...item,
          percentage: (item.value / sum * 100).toFixed(2)
        }))
      } else {
        result = this.sourceData.map(item => ({
          ...item,
          percentage: 1 / this.sourceData.length * 100
        }))
      }
      return result
    }
  },
  
  watch: {
    data(obj) {
      this.sourceData = obj
    }
  },
  
  created() {
    if (this.fetchApi) {
      this.fetchData()
    } else if(this.data) {
      this.sourceData = this.data
    }
  },
  
  methods: {
    generateStyle(item) {
      return {
        width: `${item.percentage}%`, 
        cursor: this.clickable ? 'pointer' : 'inherit', 
        marginRight: this.gutter,
        color: item.color || 'auto',
        backgroundColor: item.background || 'auto',
      }
    },
    
    handleClick(value, index) {
      if (this.clickable) {
        this.activeIndex = index
        this.$emit('clickLabel', value)
      }
    },

    formatData(data) {
      if (this.formatFunc) {
        return this.formatFunc(data)
      }
      return data
    }
  },
}
</script>

<style lang='scss' scoped>
$colors: (
  1: $primary,
  2: #67c239,
  3: #faad14,
  4: #f46b6c
);

.mf-horizontal-bar {
  width: 100%;
}

.bar-wrapper {
  display: flex;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
}

.bar {
  min-height: 32px;
  height: 32px;
  cursor: pointer;
  color: #ffffff;
  font-size: 12px;
  text-align: center;
  line-height: 32px;

  @each $i, $c in $colors {
    &:nth-child(#{$i}) {
      background-color: $c;
    }
  }

  &:last-of-type {
    margin-right: 0 !important;
  }

  &.hidden {
    display: none;
  }

  &.inactive {
    background-color: rgba(0, 0, 0, .05);
  }
}
</style>