<template>
  <div class="mf-passowrd-edit">
    <el-dialog title='切换品牌' :visible='dialogVisible' @close='handleClose' class="dialog-edit">
      <el-input placeholder="搜索品牌名称" v-model='query' size='small' />
      <div v-if="filteredBrand.length" class='brand-list'>
        <div class='item' v-for="(v) in filteredBrand" :key="v.identifier" @click="handleClickItem(v)">
          <div class='name'>{{v.name}}</div>
          <i class='icon icon-right'></i>
        </div>
      </div>
      <div v-else>
        没有符合条件的品牌
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { setStorage } from '@/utils-future/storage'
import { mapState } from 'vuex'

export default {
  name: 'changeDialog',

  data() {
    return {
      query: ''
    }
  },

  computed: {
    ...mapState({
      brand: (state) => state.user.brand,
    }),

    dialogVisible() {
      let { name } = this.$options
      return this.$store.getters.dialogVisible(name)
    },
    filteredBrand() {
      if (this.query.trim().length > 0) {
        return this.brand.filter((brand) => brand.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1)
      } else {
        return this.brand
      }
    }
  },

  methods: {
    handleClickItem(brand) {
      setStorage('identifier', brand.identifier)
      location.reload()
    },

    handleClose() {
      this.query = ''
      this.$emit('close', false)
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/ .el-dialog {
  width: 400px;
}

.brand-list {
  padding-bottom: 40px;
  width: 100%;
  margin: 0 auto;
  line-height: 1;

  .item {
    display: flex;
    align-items: center;
    padding: 15px 10px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);

    &:hover {
      background: rgba(0, 0, 0, 0.06);
    }

    .icon {
      font-size: 16px;
      line-height: 1;
    }
  }
}
</style>
