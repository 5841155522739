import { isString, isFunction } from 'lodash'

export const render = {
  name: 'render',
  
  functional: true,

  render: function(h, ctx) {
    let { render } = ctx.props
    if (render === undefined) {
      throw Error('render组件没有传入render方法！')
    }
    let renderFunc
    if (isFunction(render)) {
      renderFunc = render
    } else {
      renderFunc = h => (<div>{render}</div>)
    }
    
    return renderFunc(h)
  }
}

export const renderValue = {
  name: 'renderValue',

  functional: true,
  
  render: function(h, ctx) {
    let value = ctx.props.value
    if (value === undefined) {
      throw Error('renderValue组件没有传入value方法！')
    }
    
    let renderFunc
    if (isFunction(value)) {
      renderFunc = value
    } else {
      renderFunc = h => (<div>{value}</div>)
    }

    return renderFunc(h)
  }
}