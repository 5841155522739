<template>
  <el-select
    :value="value"
    @change="(v) => $emit('change', v)"
    class="item"
    remote
    filterable
    :placeholder="placeholder"
    :multiple="multiple"
    size="small"
    @hook:mounted="cancalReadOnly"
    @visible-change="cancalReadOnly"
    ref="select"
    clearable
  >
    <el-option
      v-for="item in data"
      :key="item.id"
      :label="`${item.displayName} (${item.name})`"
      :value="item.id"
    >
    </el-option>
  </el-select>
</template>

<script>
import { API } from '@/services/brand.js'
import { noop } from '../../utils-future/functional.js'
import { isNumber } from 'lodash'
import { useSelect } from '@/utils-future/hooks.js'
import { ref, toRefs, watch } from '@vue/composition-api'

export default {
  props: {
    value: null,
    change: {
      type: Function,
      default: noop,
    },
    placeholder: {
      type: String,
      default: '请选择员工',
    },
    multiple: {
      type: Boolean,
    },
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  setup(props, context) {
    const { state, refresh } = useSelect(context, {
      fetchApi: () => API.coachCoachList(),
    })

    return {
      ...toRefs(state),
    }
  },

  methods: {
    cancalReadOnly(onOff) {
      this.$nextTick(() => {
        if (!onOff) {
          const { select } = this.$refs
          const input = select.$el.querySelector('.el-input__inner')
          input.removeAttribute('readonly')
        }
      })
    },
  },
}
</script>

<style lang='scss' scoped>
</style>
