<template>
  <el-select
    :size="size"
    v-model="provider"
    :allow-create="allowCreate"
    :default-first-option="allowCreate"
    :filterable="allowCreate"
    v-loading="isLoading"
    placeholder="产品类型"
    class="item"
    @change="change"
  >
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    >
    </el-option>
  </el-select>
</template>

<script>
import fetch from '@/mixins/fetch'
export default {
  name: "SpProductProvider",

  mixins: [fetch],

  props: {
    value: null,
    allowCreate: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "small",
    },
    all: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      options: [],
      provider: this.value,
      fetchApi: this.$API.courseProductProviders
    };
  },

  model: {
    prop: "value",
    event: "change",
  },

  watch: {
    sourceData (val) {
      if(val) {
        if (this.all) {
          this.options = [
            {
              label: "全部品牌",
              value: null,
            },
            ...val,
          ]
        }else{
          this.options = val
        }
      }
    },

    value(val) {
      this.provider = this.value
    }
  },

  methods: {
    change(value) {
      this.$emit("change", value);
    },
  },
};

</script>

<style lang="scss" scoped>
</style>
