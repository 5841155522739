import { red } from '@/constant/colors'
import Layout from 'views/layout/Layout'

export default {
  path: '/finance',
  redirect: '/finance/sales',
  name: 'finance',
  component: Layout,
  title: '财务报表',
  hidden: false,
  icon: 'finance',
  permission: ['finance'],
  children: [
    {
      path: 'orders',
      component: () => import('views/finance/Orders'),
      // component: () => import('views/ComingSoon'),
      name: 'financeOrders',
      title: '销售报表',
      hidden: false,
      permission: ['finance', 'orders'],
      helpDoc: "https://www.yuque.com/ap1q4q/ngd5zk/vo9teu",
    },
    {
      path: 'settlements',
      component: () => import('views/finance/Settlements'),
      title: '收入报表',
      keepAlive: true,
      name: 'financeSettlements',
      redirect: '/finance/settlements/studioes',
      permission: ['finance', 'studios'],
      hidden: false,
      helpDoc: "https://www.yuque.com/ap1q4q/ngd5zk/mx2rpm",
      children: [
        {
          path: 'studioes',
          name: 'Studioes',
          component: () => import('views/finance/Settlements'),
          title: '课程收入',
          permission: ['finance', 'studios'],
        },
        {
          path: 'partners',
          name: 'Partners',
          component: () => import('views/finance/Settlements'),
          title: '储值消费收入',
          permission: ['finance', 'studios'],
        },
      ]
    },
    {
      path: 'coaches',
      component: () => import('views/finance/Coaches'),
      name: 'financeCoaches',
      title: '教练报表',
      hidden: false,
      permission: ['finance', 'coaches'],
      helpDoc: "https://www.yuque.com/ap1q4q/ngd5zk/ssnhg7"
    },
    {
      path: 'debts',
      component: () => import('views/finance/Debts'),
      name: 'financeDebts',
      title: '负债报表',
      hidden: false,
      permission: ['finance', 'debts'],
      helpDoc: "https://www.yuque.com/ap1q4q/ngd5zk/ltcla1"
    },
    {
      path: 'expirations',
      component: () => import('views/finance/Expirations'),
      redirect: '/finance/expirations/entry_account',
      name: 'financeExpirations',
      title: '到期报表',
      hidden: false,
      permission: ['finance', 'expirations'],
      children: [
        {
          path: 'entry_account',
          name: 'EntryAccount',
          component: () => import('views/finance/Expirations'),
          title: '到期会员卡',
          permission: ['finance', 'expirations'],
        },
        {
          path: 'prepay_account',
          name: 'PrepayAccount',
          component: () => import('views/finance/Expirations'),
          title: '到期储值卡',
          permission: ['finance', 'expirations'],
        },
        {
          path: 'member_coupon',
          name: 'MemberCoupon',
          component: () => import('views/finance/Expirations'),
          title: '到期代金券',
          permission: ['finance', 'expirations'],
        },
      ]
    },
  ],
}
