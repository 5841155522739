import Layout from 'views/layout/Layout'

export default {
  path: '/coaches',
  redirect: '/coaches/list',
  component: Layout,
  name: 'coaches',
  title: '员工管理',
  permission: ['coach'],
  icon: 'coach',
  hidden: false,
  children: [
    {
      path: 'coaches',
      component: () => import('views/coach/coach/CoachesList'),
      name: 'coachList',
      title: '员工人事',
      permission: ['coach', 'list'],
      keepAlive: true,
      hidden: false,
      helpDoc: "https://www.yuque.com/ap1q4q/ngd5zk/ds8cg2",
      children: [
        {
          path: ':coachId/products',
          component: () => import('views/coach/coach/components/Products'),
          title: '授权产品管理',
          // keepAlive: true,
          name: 'coachProductIndex',
          permission: ['coach', 'list'],
          root: true,
          hidden: true,
        },
      ]
    },
    {
      path: 'levels',
      component: () => import('views/coach/level/LevelsList'),
      title: '员工等级',
      permission: ['coach', 'levels'],
      name: 'CoachLevel',
      helpDoc: "https://www.yuque.com/ap1q4q/ngd5zk/ut54eg",
      hidden: false,
    },
    {
      path: 'categories',
      component: () => import('views/coach/level/CategoriesList'),
      title: '员工类型',
      permission: ['coach', 'levels'],
      name: 'CoachLevel',
      hidden: true,
      helpDoc: "https://www.yuque.com/ap1q4q/ngd5zk/ut54eg",
    },
  ],
}
