<template>
  <div class="sp-search item">
    <el-input :placeholder="placeholder" v-model='search' size='small' @keyup.enter.native='handleEnterDown' />
    <el-button type='primary' icon="el-icon-search" size='small' @click='handleClick'></el-button>
  </div>
</template>

<script>
export default {
  name: 'SpSearch',

  props: {
    placeholder: {
      type: String,
      default: '输入姓名、手机号搜索',
    },
    value: String,
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  data() {
    return {
      search: this.value,
    }
  },

  watch: {
    value() {
      this.search = this.value
    },
  },

  methods: {
    handleEnterDown() {
      this.handleClick()
    },

    handleClick() {
      let { search } = this
      this.$emit('change', search)
    },
  },
}
</script>

<style lang="scss">
.sp-search {
  display: flex;

  .el-input__inner {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
    width: 160px;
  }

  .el-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
