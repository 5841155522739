import Layout from 'views/layout/Layout'
import RouterView from 'views/layout/RouterView'
import { cloneDeep } from "lodash"

let routerView = cloneDeep(RouterView)

export default {
  path: '/studios',
  redirect: '/studios/list',
  name: 'studios',
  component: Layout,
  title: '门店管理',
  hidden: false,
  icon: 'store',
  permission: ['studio'],
  children: [
    {
      path: 'list',
      component: () => import('views/kid/studios/List'),
      name: 'studiosList',
      title: '门店列表',
      hidden: false,
      permission: ['studio', 'list'],
    },
    // {
    //   path: 'smart',
    //   component: () => import('views/kid/studios/studiosmart/Index'),
    //   name: 'studiosSmart',
    //   title: '门店智能',
    //   hidden: false,
    //   permission: ['studio', 'smart'],
    // },
    {
      path: ':studioId',
      redirect: { name: 'studioDetail' },
      component: routerView,
      name: 'studioStudioId',
      title: '门店管理',
      permission: ['studio', 'list'],
      children: [
        {
          path: 'detail',
          component: () => import('views/kid/studios/Detail'),
          name: 'studioDetail',
          permission: ['studio', 'list'],
          title: '门店详情',
        },
        {
          path: 'pictures/add',
          component: () => import('views/kid/studios/pictures/Add'),
          name: 'studioAddPictures',
          title: '添加场地图片',
        },
      ],
    },
  ],
}
