import { isObject, isArray, isString, flattenDeep } from 'lodash'

const map = ['title', 'permission', 'icon', 'keepAlive', 'helpDoc']

// 根据配置数据来生成对应的路由
function registerToRouter(router, path = '', bc = []) {
  if (!isObject(router)) {
    return false
  }
  let result = {
    meta: {},
  }
  if (!router.hidden) {
    result.hidden = true
  }
  if (!router.redirect && !router.name) {
    throw Error('必须提供name字段')
  }
  for (const key in router) {
    if (router.hasOwnProperty(key)) {
      let value = router[key]
      if (map.includes(key)) {
        result.meta[key] = value
      } else {
        result[key] = value
      }
    }
  }
  if (path === '') {
    path = router.path
  }
  if (router.root) {
    result.path = path
  }
  bc = [
    ...bc,
    {
      title: router.title,
      path,
    }
  ]
  result.meta.breadcrumb = bc
  let { children } = result
  if (isArray(children)) {
    for (let i = 0; i < children.length; i++) {
      let prefixPath = path + children[i].path
      if (path !== '') {
        prefixPath = path + '/' + children[i].path
      }
      children[i] = registerToRouter(children[i], prefixPath, bc)
    }
  }
  return result
}

// 将具有root属性的路由插入到该路由的最顶层
function insertToTopRoutes(router) {
  let result = []
  for (let i = 0; i < router.children.length; i++) {
    const element = router.children[i]
    if (element.root) {
      let temp = router.children.splice(i, 1)
      delete temp[0].root
      result.push(temp)
      i -= 1
    } else if (isArray(element.children)) {
      result.push(insertToTopRoutes(element))
    }
  }
  return result
}

// 延迟加载组件
// function importFile(url) {
//   return function(resolve) {
//     System.import(`@/${url}.vue`).then(mod => {
//       resolve(mod)
//     })
//   }
// }

// 将路径转换为驼峰
function convertCamelCase(str) {
  if (!isString(str)) {
    return
  }
  let result = str
  if (result[0] === '/') {
    result = result.slice(1)
  } else if (result[0] === '@') {
    result = result.slice(2)
  }
  return result.replace(/\/./g, function(value) {
    value = value.slice(1)
    return value.toUpperCase()
  })
}

export default function(router) {
  let result = registerToRouter(router)
  let rootRouters = flattenDeep(insertToTopRoutes(router))
  result.children.push(...rootRouters)
  return result
}
