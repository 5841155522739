<template>
  <div class="mf-overview-card" :class="{
    'single-row': !multi,
    'multi-row': multi,
  }">
    <el-row :gutter='16'>
      <el-col 
        v-for="(card, i) in data"
        :key='i'
        :span='span ? span[i] : defaultSpan'
        :style="{cursor: $get(urls, `${[i]}`) ? 'pointer' : 'auto'}"
        @click.native="handleClickUrl($get(urls, `${[i]}`))"
      >
        <div 
          :class="{
            'overview-card': true, 
            'custom-card': $get(actualColors, `${[i]}.bg`),
          }" 
          :style="{
            backgroundColor: $get(actualColors, `${[i]}.bg`, '#fff'),
            color: $get(actualColors, `${[i]}.color`, '#fff'),
          }"
        >
          <div class="overview-card-wrapper">
            <div 
              v-for="(item, j) in card" 
              class="item" 
              :class='{primary: !j}'
              :key='j'
              :style='generateStyle(j, item.url)'
              @click='handleClickItem(item.url)'
            >
              <div class="label">
                <render v-if="item.label !== undefined" :render='item.label'></render>
              </div>
              <div class="value">
                <render v-if="item.value !== undefined" :render='item.value'></render>
              </div>
            </div>
          </div>
          
          <div class="tips" v-if="$get(tips, `[${i}]tips`)">
            <el-tooltip effect="dark" :content="isString(tips[i].tips) ? tips[i].tips : ''" placement="bottom">
              <div slot="content" v-if="!isString(tips[i].tips)">
                <render :render="tips[i].tips"></render>
              </div>
              <i class="el-icon-question"></i>
            </el-tooltip>
          </div>
          
          <div 
            v-if="isNumber(patch) && i === patch && patch < data.length - 1" 
            class="patch" 
            :style="{backgroundColor: $get(colors, `${[i]}.bg`, '#fff')}"
          ></div>

          <template v-if='custom'>
            <render v-if="custom[i]" :render='custom[i]' />
          </template>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { isString, isNumber } from 'lodash'

export default {
  name: 'MfOverviewCard_v1',
  
  components: {
  },
  
  props: {
    data: {
      type: Array, // [[{label: String || jsx, value: String || jsx}]]
      // required: true,
    },
    tips: {
      type: Array, // [{tips: String || jsx}]
    },
    colors: {
      type: Array, // [{bg: String, color: String} || String]
    },
    urls: {
      type: Array, // [String]
    },
    custom: {
      type: Array, // [jsx]
    },
    multi: {
      type: Boolean,
      default: false,
    },
    patch: {
      type: Number,
    },
    span: {
      type: Array, // [Number]
    }
  },
  
  data() {
    return {
      
    }
  },
  
  computed: {
    defaultSpan() {
      return 24 / this.data.length
    },

    actualColors() {
      if (!this.colors) {
        return
      }
      return this.colors.map(o => {
        if (isString(o)) {
          return { bg: o }
        }
        return o
      })
    },
  },
  
  methods: {
    isString,

    isNumber,

    generateStyle(i, url) {
      let style = {}
      if (url) {
        style.cursor = 'pointer'
      }
      if (i && this.multi) {
        return {
          flex: 1,
          ...style,
        }
      } else {
        return {
          ...style,
        }
      }
    },

    handleClickUrl(url) {
      if (url) {
        this.$router.push(url)
      }
    },

    handleClickItem(url) {
      if (!url) {
        return
      }
      this.$router.push(url)
    },
  },
}
</script>

<style lang='scss' scoped>
.mf-overview-card {
  width: 100%;

  &.single-row {
    min-height: 104px;
  }

  &.multi-row {
    min-height: 179px;

    .primary {
      width: 100%;
      margin-bottom: 24px !important;

      .value {
        font-size: 38px;
      }
    }

    .item {
      display: block;
    }

    .value {
      margin-left: 0;
      font-size: 20px;
      font-weight: bold;
    }
  }
}

.overview-card {
  padding: 16px 24px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 4px 20px 0 rgba(#000, .02);
  position: relative;
  font-size: 14px;
  line-height: 1.571428571;
  height: 100%;

  &.custom-card {
    .label,
    .value,
    .tips {
      color: inherit !important;
    }
  }

  .patch {
    position: absolute;
    left: calc(100% - 10px);
    top: 0;
    z-index: 1;
    height: 100%;
    width: 30px;
    background-color: #f00;
  }
}

/deep/ .el-row {
  display: flex;
  align-items: stretch;
}

/deep/ .el-col {
  &:nth-child(1) .primary .value {
    color: $primary;
  }

  &:nth-child(2) .primary .value {
    color: $secondary;
  }

  &:nth-child(3) .primary .value {
    color: $green;
  }
}

.overview-card-wrapper {
  width: calc(100% + 24px);
  margin: 0 0 -12px -12px;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}

.item {
  display: flex;
  margin: 0 12px 12px !important;
}

.primary {
  margin-right: 32px;
  white-space: nowrap;
}

.label {
  color: rgba(0, 0, 0, 0.45);
}

.value {
  color: #5f6265;
  margin-left: 8px;
}

.primary {
  display: block;
  
  .value {
    font-weight: bold;
    font-size: 38px;
    line-height: 1;
    margin-top: 8px;
    margin-left: 0;
  }
}

.tips {
  position: absolute;
  top: 16px;
  color: #c0c4cc;
  right: 24px;
}
</style>