export function padNumber(num, n = 2) {
  var len = num.toString().length
  while (len < n) {
    num = '0' + num
    len++
  }
  return num
}

export function formatFloat(num, i = 2) {
  let p = Math.pow(10, i)
  return Math.floor(num * p) / p
}

export function translateGender(gender) {
  if (gender === 'male') {
    return '男'
  } else if (gender === 'female') {
    return '女'
  }
}