<template>
  <div :class="cls">
    <template v-if="type !== 'sample'">
      <div class="title-wrapper">
        <div
          class="title"
          v-for="{label} in config"
          :key='label'
          :style='style'
        > {{label}} </div>
      </div>
      <div class="item-wrapper" v-for="(item, i) in data" :key='"item" + i'>
        <div class="item" v-for="({prop, formatter}, j) in config" :key='"val" + j' :style='style'> 
          {{ formatter ? formatter(item[prop]) : item[prop] }} 
        </div>
      </div>
    </template>
    <template v-else>
      <div
        class="item-wrapper sample"
        v-for="(item, i) in data"
        :key='"item" + i'
      >
        <div class="item title" :style='style'> {{item.label}} </div>
        <div :class="`item value ${align === 'right' ? 'align-right' : ''}`" :style='style'> {{ item.formatter ? item.formatter(item.value) : item.value}} </div>
      </div>
    </template>
  </div>
</template>

<script>
import { isBoolean } from 'lodash'

export default {
  name: 'MfInfo',
  
  props: {
    data: {
      type: null,
      required: true,
    },
    config: {
      type: Array,
      validator: value => {
        return value.every(({ label, prop }) => label)
      },
    },
    gutter: {
      type: Number,
      default: 10,
    },
    border: {
      type: [Boolean, String],
      default: false,
    },
    type: {
      type: String,
    },
    align: {
      type: String,
      default: 'right',
    }
  },

  data() {
    return {}
  },

  computed: {
    cls() {
      if (isBoolean(this.border)) {
        return { 
          'mf-info': true, 
          bordered: this.border
        }
      } else {
        return {
          'mf-info': true, 
          [`bordered-${this.border}`]: true
        }
      }
    },

    style() {
      let { gutter } = this
      return {
        padding: `${gutter}px`,
      }
    },
  },

  methods: {},
}
</script>

<style lang='scss' scoped>
.mf-info {
  display: table;
  width: 100%;

  .title-wrapper,
  .item-wrapper {
    display: table-row;

    .title,
    .item {
      display: table-cell;
      padding-right: 10px;
      padding-bottom: 10px;

      &:last-child {
        padding-right: 10px;
      }
    }
  }

  .item-wrapper.sample {
    .align-right {
      text-align: right;
    }
  }

  &.bordered {
    .title,
    .item {
      border-bottom: 1px solid #eee;
      padding: 10px;
    }
  }

  &.bordered-bottom {
    .item-wrapper:last-child div {
      border-top: 1px dashed #fff;
    }
  }

  &.bordered-top {
    .item-wrapper:nth-child(2) div {
      border-top: 1px dashed #fff;
    }
  }
}
</style>