<template>
  <el-select :value='value' @change="v => $emit('change', v)" class="item" remote filterable :placeholder="placeholder"
    :multiple="multiple" size="small" clearable :remote-method="handleSearch">
    
    <el-option v-for="item in data" :key="item.id" :label="labelContent(item)" :value="item.id">
    </el-option>
  </el-select>
</template>

<script>
import { API } from '@/services/brand.js'
import { noop } from '../../utils-future/functional.js'
import { isNumber } from 'lodash'
import { useSelect } from '@/utils-future/hooks.js'
import { ref, toRefs, watch } from '@vue/composition-api'

export default {
  props: {
    value: null,
    change: {
      type: Function,
      default: noop,
    },
    placeholder: {
      type: String,
      default: '请选择课程产品',
    },
    multiple: {
      type: Boolean,
    },
    productType: {
      type: String | Array,
      default: null,
    },
    params: {}
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  setup(props, context) {
    const { state, refresh, handleSearch } = useSelect(context, {
      defaultIndex: props.defaultIndex,
      fetchApi: (query) => API.courseProductList({ ...props.params, active: true, productType: props.productType, id: props.value, query })
    })

    watch(
      () => [props.productType, props.value],
      () => refresh()
    )

    return {
      ...toRefs(state),
      handleSearch,
    }
  },

  methods: {
    labelContent: (item) => {
      if (item.forKid) {
        return `${item.name}-${item.stageList}-${item.provider||'ZPK授权'}`
      } else {
        return [item.name, item.enName, item.provider].filter(n => n).join("-")
      }
    }
  }
}
</script>

<style lang='scss' scoped>
</style>
