import store from "../store/"
import { isString } from 'lodash'

export let hasPermission = (value) => {
  if (isString(value)) {
    value = [value]
  } else if (!Array.isArray(value)) {
    throw Error('hasPermission方法参数类型必须为数组')
  }
  let {permissions} = store.state.user
  let [key, subKey] = value
  let category = permissions[key]
  if (value.length === 1 && category) {
    return true
  }
  if (category && category.includes(subKey)) {
    return true
  } else {
    return false
  }
}
