<template>
  <div class="mf-tabs" @click="change">
    <el-tabs v-model="value">
      <el-tab-pane 
        v-for="(tab, index) in actualTabs"
        :key="index"
        :label="tab.label" :name="tab.value" />
    </el-tabs>
  </div>
</template>

<script>
import { hasPermission } from '@/utils/permission'

export default {
  name: 'MfTabs',

  props: {
    currentTab: {
      type: String,
      default: 'order',
    },
    data:{
      type: Array,
      default: []
    }
  },
  
  model: {
    prop: 'currentTab',
    event: 'change',
  },
  
  data() {
    let actualTabs = this.geterateActualTabs()
    let firstPermissionedTab = this.$get(actualTabs[0], 'value')
    let value = this.currentTab ? this.currentTab : firstPermissionedTab

    return {
      value,
      actualTabs,
    }
  },
  
  watch: {
    currentTab() {
      this.value = this.currentTab
    },

    value: {
      handler: function(val) {
        if (val !== this.currentTab) {
          this.change()
        }
      },
      immediate: true,
    }
  },
  
  methods: {
    hasPermission,

    change() {
      this.$emit('change', this.value)
    },

    geterateActualTabs() {
      let result = this.data.filter(tab => tab.permission ? hasPermission(tab.permission) : true)
      return result
    }
  },
}
</script>

<style lang='scss' scoped>

</style>
