<template>
  <el-select :value='value' @change="v => $emit('change', v)" 
            class="item" 
            :placeholder="placeholder"
            :filterable="filterable"
            :clearable="clearable"
            size="small">
    <el-option v-for="item in showData" :key="item.id" :label="item.label" :value="item.value">
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: 'SpSelect',
  
  props: {
    data: Array,
    value: null,
    placeholder: {
      type: String,
      default: "请选择"
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    filterable: {
      type: Boolean,
      default: true,
    },
    includeAll: {
      type: Boolean,
      default: false,
    },
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  data() {
    return {
      
    }
  },

  computed: {
    showData() {
      let result = this.data
      if (this.includeAll && result[0].label !== '全部') {
        result = [{
          label: '全部',
          value: '',
        }, ...result]
      }
      return result
    },
  },

  methods: {
    handleClick(e) {
      let { value } = e.target.dataset
      this.$emit('change', value)
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
