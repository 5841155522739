import request from "../utils/request-future"

export const PUBLIC_API = {
  /**
   * @type {(data?: definitions["AuthFormPasswordForm"]) => Promise<import('./data').default['any']>}
   */
  authPassword: (data) => {
    return request(`/auth/password`, {
      method: "POST",
      data,
      headers: { "x-is-public": "1" },
    })
  },

  /**
   * @type {(data?: definitions["AuthFormPhoneCodeForm"]) => Promise<import('./data').default['AuthTokenDefault']>}
   */
  authPhoneCode: (data) => {
    return request(`/auth/phone_code`, {
      method: "POST",
      data,
      headers: { "x-is-public": "1" },
    })
  },

  /**
   * @type {(data?: definitions["AuthFormEditPasswordForm"]) => Promise<import('./data').default['any']>}
   */
  authEditPassword: (data) => {
    return request(`/auth/edit_password`, {
      method: "PUT",
      data,
      headers: { "x-is-public": "1" },
    })
  },

  /**
   * @type {() => Promise<import('./data').default['BrandBrandDefault'][]>}
   */
  brandGeneralGrantedBrands: () => {
    return request(`/brand/granted_brands`, {
      method: "GET",
      headers: { "x-is-public": "1" },
    })
  },

  /**
   * @type {(params: {zoneId: number, freeTraining: boolean}) => Promise<import('./data').default['HrmCourseDefault']>}
   */
  hrmCurrentCourse: (params) => {
    return request(`/hrm/current_course`, {
      method: "GET",
      params,
      headers: { "x-is-public": "1" },
    })
  },

  /**
   * @type {(params: {courseId: number}) => Promise<import('./data').default['any']>}
   */
  hrmUploadResults: (params) => {
    return request(`/hrm/upload_results`, {
      method: "POST",
      params,
      data,
      headers: { "x-is-public": "1" },
    })
  },

  /**
   * @type {(zoneId: number) => Promise<import('./data').default['HrmTrainingDataDefault']>}
   */
  hrmTrainingData: (zoneId) => {
    return request(`/tas/zones/${zoneId}/hrm/training_data`, {
      method: "GET",
      headers: { "x-is-public": "1" },
    })
  },

  /**
   * @type {() => Promise<import('./data').default['any']>}
   */
  i18nModelAttributes: () => {
    return request(`/i18n/model_attributes`, {
      method: "GET",
      headers: { "x-is-public": "1" },
    })
  },

  /**
         * @type {(params: {brand_id?: number}, data?: {
"page"?: string;
"params"?: { [key: string]: any };
"width"?: number;

}) => Promise<import('./data').default['any']>}
         */
  qrCodeLimtedCode: (params, data) => {
    return request(`/limited_qr_code`, {
      method: "POST",
      params,
      data,
      headers: { "x-is-public": "1" },
    })
  },

  /**
         * @type {(params: {brand_id?: number}, data?: {
"page"?: string;
"params"?: { [key: string]: any };
"width"?: number;

}) => Promise<import('./data').default['any']>}
         */
  qrCodeUnlimtedCode: (params, data) => {
    return request(`/unlimited_qr_code`, {
      method: "POST",
      params,
      data,
      headers: { "x-is-public": "1" },
    })
  },

  /**
   * @type {() => Promise<import('./data').default['TasBlockTypePagedArrayDefault']>}
   */
  tasBlockTypeIndex: () => {
    return request(`/tas/block_types`, {
      method: "GET",
      headers: { "x-is-public": "1" },
    })
  },

  /**
   * @type {(data: any) => Promise<import('./data').default['any']>}
   */
  fileUploadCreateTest: (data) => {
    return request(`/test_files`, {
      method: "POST",
      data,
      headers: { "x-is-public": "1" },
    })
  },
}
