import Layout from 'views/layout/Layout'

export default {
  path: '/social',
  redirect: '/social/footprint',
  name: 'social',
  component: Layout,
  title: '社群管理',
  hidden: false,
  icon: 'link',
  permission: ['sns'],
  children: [
    // {
    //   path: 'community',
    //   redirect: '/social/community/list',
    //   component: () => import('views/layout/RouterView'),
    //   title: '社群管理',
    //   name: 'socialCommunityDashboard',
    //   permission: ['sns', 'community'],
    //   hidden: false,
    //   children: [
    //     {
    //       path: 'list',
    //       component: () => import('views/social/community/Index'),
    //       title: '社群列表',
    //       name: 'socialCommunity'
    //     },
    //     {
    //       path: 'add',
    //       component: () => import('views/social/community/Add'),
    //       title: '添加社群',
    //       name: 'socialAddCommunity'
    //     },
    //     {
    //       path: ':communityId/edit',
    //       component: () => import('views/social/community/Edit'),
    //       title: '修改社群',
    //       name: 'socialEditCommunity'
    //     },
    //     // {
    //     //   path: ':communityId/award/edit',
    //     //   component: () => import('views/social/community/award/Edit'),
    //     //   title: '修改奖励信息',
    //     //   name: 'socialEditAward'
    //     // },
    //     {
    //       path: ':communityId/detail',
    //       component: () => import('views/social/community/Detail'),
    //       title: '社群详情',
    //       name: 'socialCommunityDetail'
    //     },
    //     {
    //       path: ':communityId/activity/add',
    //       component: () => import('views/social/community/activity/Add'),
    //       title: '添加社群活动',
    //       name: 'socialAddCommunityActivity'
    //     },
    //     {
    //       path: ':communityId/activity/:activityId/edit',
    //       component: () => import('views/social/community/activity/Edit'),
    //       title: '修改社群活动',
    //       name: 'socialEditCommunityActivity'
    //     },
    //     {
    //       path: ':communityId/article/add',
    //       component: () => import('views/social/community/article/Add'),
    //       title: '添加官方信息',
    //       name: 'socialAddCommunityArticle'
    //     },
    //     {
    //       path: ':communityId/article/:articleId/edit',
    //       component: () => import('views/social/community/article/Edit'),
    //       title: '修改官方信息',
    //       name: 'socialEditCommunityArticle'
    //     },
    //     {
    //       path: ':communityId/activity/:activityId/activty-courses',
    //       component: () => import('views/social/community/activity/ActivityCourses'),
    //       title: '修改社群活动课程',
    //       name: 'socialCommunityActivityCourse'
    //     },
    //   ]
    // },
    {
      path: 'community_statistic',
      redirect: '/social/community_statistic/statistic',
      component: () => import('views/layout/RouterView'),
      title: '社群统计',
      name: 'socialCommunityStatistic',
      permission: ['sns', 'community'],
      hidden: false,
      children: [
        {
          path: 'statistic',
          component: () => import('views/social/community/Statistic'),
          title: '社群统计',
          name: 'communityStatistic'
        }
      ]
    },
    {
      path: 'list',
      component: () => import('views/operating_tools/campaign/List'),
      name: 'operatingToolsCampaignList',
      title: '活动管理',
      hidden: false,
      permission: ['operating_tools', 'campaign'],
    },
    {
      path: 'add',
      component: () => import('views/operating_tools/campaign/Add'),
      name: 'operatingToolsCampaignAdd',
      title: '添加活动',
    },
    {
      path: 'campaign/:campaignId',
      redirect: '/operating_tools/list',
      component: () => import('views/layout/RouterView'),
      name: 'operatingToolsCampaign',
      title: '活动管理',
      permission: ['operating_tools', 'campaign'],
      children: [
        {
          path: 'detail',
          component: () => import('views/operating_tools/campaign/Detail'),
          name: 'operatingToolsCampaignDetail',
          title: '活动详情',
        },
        {
          path: 'edit',
          component: () => import('views/operating_tools/campaign/Edit'),
          name: 'operatingToolsCampaignEdit',
          title: '编辑活动',
        },
        {
          path: 'pictures/add',
          component: () => import('views/operating_tools/campaign/PicturesAdd'),
          name: 'operatingToolsPicturesAdd',
          title: '添加照片',
        },
        {
          path: 'pictures/edit',
          component: () => import('views/operating_tools/campaign/PicturesEdit'),
          name: 'operatingToolsPicturesEdit',
          title: '编辑活动照片信息',
        },
        {
          path: 'campaign_images/add',
          component: () => import('views/operating_tools/campaign/CampaignImagesAdd'),
          name: 'operatingToolsCampaignImagesAdd',
          title: '添加相册照片',
        },
        {
          path: 'bg_images/add',
          component: () => import('views/operating_tools/campaign/BgImagesAdd'),
          name: 'operatingToolsBgImagesAdd',
          title: '添加背景图',
        },
        {
          path: 'courses/edit',
          component: () => import('views/operating_tools/campaign/CoursesEdit'),
          name: 'operatingToolsCoursesEdit',
          title: '编辑活动课程',
        },
        {
          path: 'courses/pair_free_edit',
          component: () => import('views/operating_tools/campaign/PairFreeEdit'),
          name: 'operatingToolsPairFreeEdit',
          title: '编辑',
        },
        {
          path: 'courses/course_edit',
          component: () => import('views/operating_tools/campaign/CoursesEdit'),
          name: 'operatingToolsCoursesCourseEdit',
          title: '编辑活动课程',
        },
      ],
    },
    {
      path: 'footprint',
      component: () => import('views/social/footprint/Index'),
      name: 'socialFootprint',
      title: '记忆相册',
      permission: ['sns', 'footprint'],
      hidden: false,
    },
    {
      path: 'leaderboards',
      component: () => import('views/social/leaderboards/Index'),
      name: 'socialLeaderboards',
      title: '榜单列表',
      permission: ['sns', 'leaderboard'],
      hidden: false,
    },
    {
      path: 'leaderboards/:metaInfoId',
      component: () => import('views/social/leaderboards/Detail'),
      name: 'leaderboardsDetail',
      permission: ['sns', 'leaderboard'],
      title: '榜单详情',
    },
  ],
}
