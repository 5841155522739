import Layout from 'views/layout/Layout'
import RouterView from 'views/layout/RouterView'

export default {
  path: '/operating_tools',
  redirect: '/operating_tools/list',
  component: Layout,
  name: 'operatingTools',
  title: '运营工具',
  hidden: false,
  permission: ['operating'],
  icon: 'tool',
  children: [
    {
      path: 'advertisements',
      component: RouterView,
      // component: () => import('views/ComingSoon'),
      name: 'operatingToolsAdvertisement',
      redirect: '/operating_tools/advertisements/detail',
      permission: ['operating', 'ads'],
      title: '广告管理',
      hidden: false,
      children: [
        {
          path: 'detail',
          component: () => import('views/kid/operating_tools/advertisements/Detail'),
          name: 'operatingToolsAdvertisementDetail',
          title: '广告详情',
          permission: ['operating', 'ads'],
        },
        {
          path: 'add',
          component: () => import('views/kid/operating_tools/advertisements/Add'),
          name: 'operatingToolsAdvertisementAdd',
          title: '添加广告',
        },
        {
          path: 'edit',
          component: () => import('views/kid/operating_tools/advertisements/Edit'),
          name: 'operatingToolsAdvertisementEdit',
          title: '编辑广告',
        },
      ],
    },
    // {
    //   path: 'marketing',
    //   component: () => import('views/operating_tools/marketing/Index'),
    //   name: 'marketing',
    //   title: '运营记录',
    //   hidden: false,
    //   permission: ['operating', 'marketing'],
    // },
    {
      path: 'milestones',
      component: () => import('views/kid/operating_tools/milestones/Index'),
      name: 'milestones',
      title: '纪念时刻',
      hidden: false,
      permission: ['operating', 'milestones'],
    },
    {
      path: 'rating',
      component: () => import('views/kid/operating_tools/rating/Index'),
      title: '评价管理',
      keepAlive: false,
      name: 'operatingToolsRating',
      permission: ['operating', 'ratings'],
      hidden: false,
    },
    {
      path: 'reviews',
      component: () => import('views/kid/operating_tools/reviews/Index'),
      title: '评论管理',
      keepAlive: false,
      name: 'operatingToolsReviews',
      permission: ['operating', 'reviews'],
      hidden: false,
    },
    // TODO: move communications and training results to here
  ],
}

