import Layout from 'views/layout/Layout'

export default {
  path: '/tas',
  redirect: '/tas/exercises',
  name: 'tas',
  component: Layout,
  title: '团课内容管理',
  permission: ['tas'],
  hidden: false,
  icon: 'grouptraining',
  children: [
    {
      path: 'exercises',
      component: () => import('views/tas/exercises/Index'),
      title: '动作库',
      keepAlive: true,
      name: 'ExerciseIndex',
      permission: ['tas', 'exercises'],
      hidden: false,
    },
  ],
}
