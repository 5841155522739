<template>
  <el-button-group class="item">
    <el-button v-for="obj in data" :class="{'active': obj.value === value}" :key="obj.value" :data-value='obj.value'
              @click="handleClick" size='small'>
      {{obj.label}}
    </el-button>
  </el-button-group>
</template>

<script>
export default {
  name: 'SpButtonGroup',

  props: {
    data: Array, // [{label: '', value: ''}]
    value: null,
    initialValue: null
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  created() {
    if (this.value == null) {
      this.value = this.initialValue
    }
  },

  computed: {},

  methods: {
    handleClick(e) {
      let { value } = e.target.dataset
      this.$emit('change', value || Boolean(value))
    },
  },
}
</script>

<style lang="scss" scoped>
.el-button + .el-button {
  margin-left: 0;
}

/deep/ .el-button {
  span {
    pointer-events: none;
  }

  &:hover,
  &:focus,
  &.active {
    border-color: $primary;
    background-color: $primary;
    color: #ffffff;
  }
}
</style>
