<template>
  <div class="mf-editable" :class='{disabled, transparent}'>
    <el-tooltip content="点击可编辑">
      <el-input 
        ref='input'
        v-model="text"
        v-bind="$attrs"
        @blur='handleChange'
        @keyup.enter.native='handleEnter'
      >
      </el-input>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  name: 'MfEditable',
  
  props: {
    value: null,
    disabled: {
      type: Boolean,
      default: false,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
  },
  
  model: {
    prop: 'value',
    event: 'change',
  },
  
  data() {
    return {
      text: this.value
    }
  },
  
  watch: {
    value(val) {
      this.text = val
    }
  },
  
  methods: {
    handleChange() {
      if (this.text !== this.value) {
        this.$emit('change', this.text)
      }
    },

    handleEnter() {
      this.$refs.input.blur()
    }
  },

  inheritAttrs: false,
}
</script>

<style lang='scss' scoped>
/deep/ input,
/deep/ textarea {
  width: 100%;
  border: 0;
  padding: 0;
  resize: none;

  &:focus {
  }
}

.disabled {
  pointer-events: none;
}

.transparent {
  /deep/ input {
    background-color: transparent;
    border: 0;
    color: #ffffff;
  }
}
</style>