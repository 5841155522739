<template>
  <el-select :value='value' @change="v => $emit('change', v)" class="item" remote filterable :placeholder="placeholder"
             size="small" clearable>
    <el-option v-for="item in data" :key="item.id" :label="item.name" :value="item.id">
    </el-option>
  </el-select>
</template>

<script>
import { API } from '@/services/brand.js'
import { noop } from '../../utils-future/functional.js'
import { isNumber } from 'lodash'
import { useSelect } from '@/utils-future/hooks.js'
import { ref, toRefs, watch } from '@vue/composition-api'

export default {
  props: {
    studioId: {
      type: Number,
      default: null,
    },
    value: null,
    change: {
      type: Function,
      default: noop,
    },
    placeholder: {
      type: String,
      default: '请选择门店',
    },
    defaultIndex: Number,
    all: {
      type: Boolean,
      default: false,
    },
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  setup(props, context) {
    const { state, refresh } = useSelect(context, {
      all: props.all,
      defaultIndex: props.defaultIndex,
      fetchApi: () =>
        props.studioId ? API.studioZoneList(props.studioId) : noop(),
    })

    watch(
      () => props.studioId,
      (v) => {
        refresh()
      }
    )

    return {
      ...toRefs(state),
    }
  },
}
</script>

<style lang='scss' scoped>
</style>